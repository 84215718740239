import { React, useState } from 'react'
import {motion} from "framer-motion"
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { setUser } from '../../actions'
//import { Link } from 'react-router-dom'

const CookiePanel = (props) => { 
    const getCookie = (name) => {
        let value = `; ${document.cookie}`;
        let parts = value.split(`; ${name}=`);
        if (parts.length === 2) {
          console.log("cookie", parts.pop().split(';').shift())
          //return parts.pop().split(';').shift();
          var r = parts.pop().split(';').shift();
          return r==="true";
        } else {
          console.log("cookie", null);
          return null;
        }
    } 
    const intl = useIntl();
    const [c, setC] = useState(getCookie("consent")/*==="true"*/)
    const [cm, setCM] = useState(getCookie("consent_m")/*==="true"*/)

  const setCookie = (value) => {      
    setC(value);
    /*if (value){        
        document.cookie = `consent=true; path=/; max-age=${60 * 60 * 24 * 365};`;
        window.dataLayer.push('consent','update', {'analytics_storage':'granted'});
        //window.dataLayer.push('consent','update', {'ad_storage':'granted'});
        //window.dataLayer.push('consent','update', {'functionality_storage':'granted'});
        //window.dataLayer.push('consent','update', {'personalization_storage':'granted'});
        //window.dataLayer.push('consent','update', {'security_storage':'granted'});
    } else {
        //console.log("Deny cookies");
        document.cookie = `consent=false; path=/; max-age=${60 * 60 * 24 * 1};`;
        window.dataLayer.push('consent','update', {'analytics_storage':'denied'});
        //window.dataLayer.push('consent','update', {'ad_storage':'denied'});
        //window.dataLayer.push('consent','update', {'functionality_storage':'denied'});
        //window.dataLayer.push('consent','update', {'personalization_storage':'denied'});
        //window.dataLayer.push('consent','update', {'security_storage':'denied'});
    }*/
  }
  const setCookieM = (value) => {      
    setCM(value);
    /*if (value){      
      document.cookie = `consent_m=true; path=/; max-age=${60 * 60 * 24 * 365};`;
      window.dataLayer.push('consent','update', {'ad_storage':'granted'});
    } else {      
      document.cookie = `consent_m=false; path=/; max-age=${60 * 60 * 24 * 1};`;
      window.dataLayer.push('consent','update', {'ad_storage':'denied'});
    }*/
  }

  const applyChanges = () => {
    if(c){
      document.cookie = `consent=true; path=/; max-age=${60 * 60 * 24 * 365};`;
      window.dataLayer.push('consent','update', {'analytics_storage':'granted'});      
    } else {      
      document.cookie = `consent=false; path=/; max-age=${60 * 60 * 24 * 1};`;
      window.dataLayer.push('consent','update', {'analytics_storage':'denied'});
    }
    if (cm){     
      document.cookie = `consent_m=true; path=/; max-age=${60 * 60 * 24 * 365};`;
      window.dataLayer.push('consent','update', {'ad_storage':'granted'});
    } else {     
      document.cookie = `consent_m=false; path=/; max-age=${60 * 60 * 24 * 1};`;
      window.dataLayer.push('consent','update', {'ad_storage':'denied'});
    }
    props.close();
  }

  const getC = () =>{
    //if (getCookie("consent")===null){
      if (c){
        return "btn btn-small active";
      }
    //}
    return "btn btn-small";
  }
  const getNotC = () =>{
    //if (getCookie("consent")===null){
      if (!c && c!==null){
        return "btn btn-small active";
      }
    //}    
    return "btn btn-small";
  }

  const getCm = () =>{
    //if (getCookie("consent_m")===null){
      if (cm){
        return "btn btn-small active";
      }
    //}    
    return "btn btn-small";
  }

  const getNotCm = () =>{
    //if (getCookie("consent_m")===null){
      if (!cm && cm!==null){
        return "btn btn-small active";
      }
    //}
    return "btn btn-small";
  }

  return <div   className="modal modalOK modalF1 modalDelete modalCookie">
    <motion.div animate={{ opacity:1, x: "-50%", y: "-50%",  transition: { duration: 0.5, type: "spring", bounce: 0.5 }}} initial={{opacity:0}} exit={{opacity:0, transition: { duration: 0.2 } }} layout className="modalWrapper">
      <div className="modalContent">
            <div className="right">
                <h2>
                {intl.formatMessage({ id: "app.cookies.personaliza2" })}      
                </h2>               
                <p>{intl.formatMessage({ id: "app.cookies.weuse2" })} 
                <br/>{intl.formatMessage({ id: "app.cookies.formore" })} <a style={{color: "black", textDecoration: "underline"}} href={process.env.REACT_APP_URL+"?page=cookie-policy"}>{intl.formatMessage({ id: "app.footer.cookies" })}</a></p>
                <div className="buttons" >
                    <p>{intl.formatMessage({ id: "app.cookies.cookies_a" })}:</p>
                    <div>
                    <span><button className={getC()} onClick={ ()=> { setCookie(true) } }>{/*intl.formatMessage({ id: "app.cookies.si" })*/}</button>{intl.formatMessage({id:"app.cookies.accept"})}</span>
                    <span><button className={getNotC()} onClick={ ()=> { setCookie(false) } }>{/*intl.formatMessage({ id: "app.cookies.no" })*/}</button>{intl.formatMessage({id:"app.cookies.notaccept"})}</span>
                    
                    </div>
                </div>
                <div className="buttons" >
                    <p>{intl.formatMessage({ id: "app.cookies.cookies_b" })}:</p>
                    <div>
                    <span><button className={getCm()} onClick={ ()=> { setCookieM(true) } }>{/*intl.formatMessage({ id: "app.cookies.si" })*/}</button>{intl.formatMessage({id:"app.cookies.accept"})}</span>
                    <span><button className={getNotCm()} onClick={ ()=> { setCookieM(false) } }>{/*intl.formatMessage({ id: "app.cookies.no" })*/}</button>{intl.formatMessage({id:"app.cookies.notaccept"})}</span>                    
                    </div>
                </div>
                <div className="buttons right" >
                  <button onClick={()=>{ applyChanges() }} className="btn">{intl.formatMessage({ id: "app.cookies.save" })}</button>    
                </div>
            </div>            
      </div>     
    </motion.div>
  </div>
}
//export default ModalDelete
const mapStateToprops = (state)=>{  
  return {...state};
}
export default connect(mapStateToprops,{setUser:setUser})(CookiePanel)