import React, {/*useState, useEffect,*/ useContext} from "react";
import {Context} from "../lang/Wrapper";

const HeaderGP = (props) => {

    //const [gp,setGp] = useState(null)
    const context = useContext(Context);    
    const gps = [
        {
            "idgran_premio" : 1,
            "nombre" : "España",
            "name" : "Spain",
            "icon" : "1.svg",           
        },
        {
            "idgran_premio" : 2,
            "nombre" : "Baréin",
            "name" : "Bahrain",
            "icon" : "2.svg",           
        },
        {
            "idgran_premio" : 3,
            "nombre" : "Arabia Saudita",
            "name" : "Saudi Arabia",
            "icon" : "3.svg",            
        },
        {
            "idgran_premio" : 4,
            "nombre" : "Australia",
            "name" : "Australia",
            "icon" : "4.svg",            
        },
        {
            "idgran_premio" : 5,
            "nombre" : "Azerbaiyán",
            "name" : "Azerbaijan",
            "icon" : "5.svg",            
        },
        {
            "idgran_premio" : 6,
            "nombre" : "Miami",
            "name" : "Miami",
            "icon" : "6.svg",            
        },
        {
            "idgran_premio" : 7,
            "nombre" : "Emilia Romagna",
            "name" : "Emilia Romagna",
            "icon" : "7.svg",            
        },
        {
            "idgran_premio" : 8,
            "nombre" : "Mónaco",
            "name" : "Monaco",
            "icon" : "8.svg",            
        },
        {
            "idgran_premio" : 9,
            "nombre" : "Canadá",
            "name" : "Canada",
            "icon" : "9.svg",            
        },
        {
            "idgran_premio" : 10,
            "nombre" : "Austria",
            "name" : "Austria",
            "icon" : "10.svg",           
        },
        {
            "idgran_premio" : 11,
            "nombre" : "Gran Bretaña",
            "name" : "Great Britain",
            "icon" : "11.svg",            
        },
        {
            "idgran_premio" : 12,
            "nombre" : "Hungría",
            "name" : "Hungary",
            "icon" : "12.svg",            
        },
        {
            "idgran_premio" : 13,
            "nombre" : "Bélgica",
            "name" : "Belgium",
            "icon" : "13.svg",            
        },
        {
            "idgran_premio" : 14,
            "nombre" : "Países Bajos",
            "name" : "Netherlands",
            "icon" : "14.svg",            
        },
        {
            "idgran_premio" : 15,
            "nombre" : "Italia",
            "name" : "Italy",
            "icon" : "15.svg",            
        },
        {
            "idgran_premio" : 16,
            "nombre" : "Singapur",
            "name" : "Singapore",
            "icon" : "16.svg",            
        },
        {
            "idgran_premio" : 17,
            "nombre" : "Japón",
            "name" : "Japan",
            "icon" : "17.svg",            
        },
        {
            "idgran_premio" : 18,
            "nombre" : "Qatar",
            "name" : "Qatar",
            "icon" : "18.svg",            
        },
        {
            "idgran_premio" : 19,
            "nombre" : "Estados Unidos",
            "name" : "United States",
            "icon" : "19.svg",            
        },
        {
            "idgran_premio" : 20,
            "nombre" : "México",
            "name" : "Mexico",
            "icon" : "20.svg",            
        },
        {
            "idgran_premio" : 21,
            "nombre" : "Brasil",
            "name" : "Brazil",
            "icon" : "21.svg",            
        },
        {
            "idgran_premio" : 22,
            "nombre" : "Las Vegas",
            "name" : "Las Vegas",
            "icon" : "22.svg",
        },            
        {
            "idgran_premio" : 23,
            "nombre" : "Abu Dhabi",
            "name" : "Abu Dhabi",
            "icon" : "23.svg",            
        },
        {
            "idgran_premio" : 24,
            "nombre" : "China",
            "name" : "China",
            "icon" : "24.svg",            
        }
        ];
    //console.log("GP", props.gp);
    var gp = gps.find(function (item) {
        return item.idgran_premio === props.idgp;
    });

   
  
    return  <>    
            {
            (gp !== null) ?
                <>
                    <img src={process.env.PUBLIC_URL + "../images/flags/" + gp.icon} />
                    <h2>
                        {                            
                            context.locale.toUpperCase()==="EN" ? gp.name + " GP™" : context.locale.toUpperCase()==="PL" ? "Grand Prix™ " + gp.name : "GP™ de " + gp.nombre
                        }
                    </h2>
                </>
                : null
            }
            </>
};

export default HeaderGP;
