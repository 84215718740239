import { useState, useEffect } from "react";

// Hook
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    var prevWidth = window.innerWidth;
    var first = true;    
    function handleResize() {
      // Set window width/height to state
      if (window.innerWidth !== prevWidth || first) {
        //console.log("handle-Resize", window.innerWidth, prevWidth);
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
        first = false;
        prevWidth = window.innerWidth;
      }/*else{
        console.log("NO handle-Resize", window.innerWidth, prevWidth);
      }*/
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export default useWindowSize