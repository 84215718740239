import React from 'react'

const Button = (props) => {
    var textColor="white"
    var buttonProps = {}

    if(props.color==="white"){
        textColor="red"
    }

    if (typeof props.idButton !== 'undefined'){
        buttonProps.id = props.idButton;
    } 
    
    return  <>               
                <button {...buttonProps} onClick={props.clicked} className={"button-container button-" + props.color}>
                    {props.text !== undefined ?
                        <div className='text'>
                            <span className={"text-"+textColor} >{props.text}</span>
                        </div>
                        :null
                    }
                    <div className='icon'>
                        {typeof props.icon !== 'undefined'?
                        <img src={props.icon}/>:null}
                    </div>
                </button>
            </>
}

export default Button