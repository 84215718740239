import React, { useContext/*, useEffect*/ } from 'react'
import { Link } from 'react-router-dom'
import {FormattedMessage} from 'react-intl';
import logosantander from '../../assets/images/santander-logo.svg'
import twitter from '../../assets/images/twitter.svg'
import linkedin from '../../assets/images/linkedin.svg'
import facebook from '../../assets/images/facebook.svg'
import instagram from '../../assets/images/instagram.svg'
import youtube from '../../assets/images/youtube.svg'
import {Context} from "../lang/Wrapper"
//import {Countries} from '../Login/Countries'
//import { connect } from 'react-redux'


const Footer = (/*props*/) => {
  const context = useContext(Context);
  //if (props.page==="login") return null;
  //return null;
  //var link = (navigator.language.includes("es"))?   process.env.REACT_APP_URL +"images/bases-legales/Santander-Formula1-Bases_Legales_EN.pdf": process.env.REACT_APP_URL +"images/bases-legales/Santander-Formula1-Bases_Legales_ES.pdf";

  var link = context.locale.toUpperCase()==="EN"?process.env.REACT_APP_URL +"images/bases-legales/Santander-Formula1-Bases_Legales_EN.pdf":context.locale.toUpperCase()==="PL"?process.env.REACT_APP_URL +"images/bases-legales/Santander-Formula1-Bases_Legales_PL.pdf":process.env.REACT_APP_URL +"images/bases-legales/Santander-Formula1-Bases_Legales_ES.pdf"

  /*const baseleglesLink = () => {
    var f = "";
    if (navigator.language.includes("es")){
        f = "Santander-Formula1-Bases_Legales_EN.pdf";
    } else {
        f = "Santander-Formula1-Bases_Legales_ES.pdf";
    }
    return process.env.REACT_APP_URL + "images/bases-legales/"+f;
  }*/

  return <footer className="footer">
    <div className="container">
      <div className="flex">
      <img src={logosantander} className="logo" alt="Banco Santander" />
        <div>
          <p>        
            <FormattedMessage
              id = "app.footer.text"
              defaultMessage=""
            />
          </p>
          <br/>
          <p>        
            <FormattedMessage
              id = "app.footer.text2"
              defaultMessage=""
            /><br/>
            <FormattedMessage
              id = "app.footer.text3"
              defaultMessage=""
            />
          </p>
          <br/>
          <div className="legal">
            <Link to="/cookie-policy"><FormattedMessage
              id = "app.footer.cookies"
              defaultMessage=""
            /></Link>
            {<Link to="/privacy-policy"><FormattedMessage
              id = "app.footer.legal"
              defaultMessage=""
            /></Link>}
             <a href={link} ><FormattedMessage
              id = "app.register.conditions"
              defaultMessage=""
            /></a>            
            <Link to="/legal-advice"><FormattedMessage
              id = "app.footer.legal2"
              defaultMessage=""
            /></Link>
          </div>
        </div>
        <div className="rrss">
          <ul>
            <li><a target="_blank" rel="noreferrer" href="https://twitter.com/SantanderGP?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"><img src={twitter}></img></a></li>
            <li><a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/banco-santander/"><img src={linkedin}></img></a></li>
            <li><a target="_blank" rel="noreferrer" href="https://www.facebook.com/formulasantander/?locale=es_ES"><img src={facebook}></img></a></li>
            <li><a target="_blank" rel="noreferrer" href="https://www.instagram.com/formulasantander/"><img src={instagram}></img></a></li>
            <li><a target="_blank" rel="noreferrer" className="yt" href="https://www.youtube.com/@SantanderGroup/featured"><img src={youtube}></img></a></li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
}

export default Footer
/*const mapStateToprops = (state)=>{        
  return {...state};  
}
export default connect(mapStateToprops)(Footer)*/