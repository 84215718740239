import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { setUser } from '../../actions'
import {Context} from "../lang/Wrapper"
import axios from 'axios'
import Spain from '../../assets/images/flags/0.svg'
import Trophy from '../../assets/images/trophy.svg'
import hits from '../../assets/images/check.svg'
import delay from '../../assets/icons/slipstreaming.svg'
import pending from '../../assets/images/timer.svg'
import { useIntl } from 'react-intl'
import {motion, AnimatePresence} from "framer-motion"
import ModalDelete from './ModalDelete'
import ModalSpecial from './SpecialGP'
import {Countries} from '../Login/Countries'

const Quizes = (props) => {
    const intl = useIntl();
    const context = useContext(Context);
    const navigate = useNavigate();
    const [gps, setGps] = useState(null);
    //const [ranking, setRanking] = useState(null);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showModalSpecial, setShowModalSpecial] = useState(false);
    const [special, setSpecial] = useState(null);

    var instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: 26000,
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('JWT') }
      });

    const removeUser = () =>{
        if (localStorage.getItem("A") !== null && localStorage.getItem("B") !== null && props.user.id != null){
            //1. Remove user 
            //2. logout user
            //3. launch Modal
            //4. redirect to home
            const a = localStorage.getItem("A");
            const b = localStorage.getItem("B")
            instance.get('/api/user/delete/' + a + '/' + b).then( (/*response*/)=>{
                //console.log(response);
                localStorage.removeItem('JWT');
                localStorage.removeItem('A');
                localStorage.removeItem('B');
                /*setTimeout( () =>{
                    props.setUser(null);
                }, 1200);*/
                setShowModalDelete(true);
            }).catch( (error)=>{
                console.log("Error:", error);
                //localStorage.removeItem('JWT');
                localStorage.removeItem('A');
                localStorage.removeItem('B');
                /*setTimeout( () =>{
                    props.setUser(null);
                }, 5000);*/
                //setShowModalDelete(true);
            })

        }
    } 
    
    const getCountry = () => {
        var l = navigator.language;
        var parts = l.split("-");
        var c = parts[parts.length-1].toUpperCase();
        var found = "Other";
        Countries.getCountries().map((country) => {
            if (country.alpha2Code.toUpperCase() === c)
            found = country.name
        });
        //console.log(found);
        return { name: found, code:c, lang: l};
    }

    useEffect(() => {
        setLoading(true);
        window.scrollTo(0, 0);      
        props.setPage('dashboard');        
        instance.get('/api/user').then( (response)=>{
            window.dataLayer.push({
                'userId' : response.data.user.id                
            });
            window.dataLayer.push({
                'user_country' : getCountry()
            });
            if (!sessionStorage.getItem("RELOAD")){
                //console.log("reload");
                sessionStorage.setItem("RELOAD", 1);
                //https://www.bounteous.com/insights/2018/03/30/single-page-applications-google-analytics
                window.dataLayer.push({event: 'pageview', page: { path: '/dashboard', title: 'Dashboard' }});
                //window.location.reload();
            } else{
                removeUser();
            }           
            props.setUser({"id": response.data.user});
            
            context.selectLanguage(response.data.user.idioma);            
            if (response.data.user.newUser){
                if (!localStorage.getItem('INTRO')){
                    navigate("/intro");
                }
            } else{
                localStorage.removeItem("INTRO");
            }
            
        }).catch( (error)=>{
            console.log("Error:", error);
            navigate("/login");
        })
        
        instance.get('/api/gps').then( (response)=>{          
            const gpsMod=getNext(response.data.gps)
            setGps(gpsMod)
            setLoading(false);
        }).catch( (error)=>{
              console.log("Error:", error);
        })       
    },[]);

    const getNext = (gps) => {
        var next=false;
        var i

        for(i=0; i < gps.length; i++){
            if(gps[i].active){
                next=true
                if (gps[i].special==="1" && gps[i].current && !gps[i].done){
                    if (localStorage.getItem("SPECIAL") !== gps[i].idgran_premio){
                        setShowModalSpecial(true);
                        setSpecial(gps[i].idgran_premio);
                    }
                }
            }else if(next){
                gps[i].next=true
                next=false
            }
         
        }
        return gps
    }

    const getMonth = (date) => {
        var locale = props.user.id.idioma.toUpperCase()==="ES"?"es-ES": props.user.id.idioma.toUpperCase()==="EN"?"en-EN":"pl-PL";
        return date.toLocaleString(locale, { month: 'short' });
    }
    
    const getPosition = (number, isDate=false) =>{
        var locale = props.user.id.idioma.toUpperCase()==="ES"?"es-ES":props.user.id.idioma.toUpperCase()==="PL"?"pl-PL":"en-EN";
        if (locale === "en-EN"){
            if (number%10===1)return <sup>st</sup>;
            if (number%10===2)return <sup>nd</sup>;
            if (number%10===3)return <sup>rd</sup>;
            return <sup>th</sup>;
        }
        if (locale === "es-ES"){
            return isDate?"":"º";
        }
        if (locale === "pl-PL"){
            return "";
        }
    }

    const getDate = (gp) =>{
        //return (16th-18th June) if same month or (30th June -2nd July) if diff month        
        var desde = new Date(gp.desde);
        var hasta = new Date(gp.hasta);        
        if (desde.getMonth() === hasta.getMonth()){
            return <span>{desde.getDate()}{getPosition(desde.getDate(),true)}-{hasta.getDate()}{getPosition(hasta.getDate(),true)} {getMonth(desde)}</span>;
        } else {
            return <span>{desde.getDate()}{getPosition(desde.getDate(),true)} {getMonth(desde)}-{hasta.getDate()}{getPosition(hasta.getDate(),true)} {getMonth(hasta)}</span>;
        }        
    }

    /*const getDone = (gp) => {
        var result = false;
        if (ranking !== null){
            ranking.GP.forEach(element => {               
                if (element.id === gp.idgran_premio){
                    if (element.current_user.totalTiempo !== null){
                        if ( !isNaN(element.current_user.totalTiempo)){
                            result = true;
                        }
                    }
                }
            });
        }
        return result;
    }*/

    /*const getGPPosition = (gp) => {
        var position="-";
        if (ranking!==null){
            ranking.GP.forEach(element => {                
                if (element.id === gp.idgran_premio){                    
                    if (!isNaN(element.current_user.totalAciertos)){
                        position = parseInt(element.current_user.posicion);// + 1;
                    }                    
                }
            });
            return <span>{position}{getPosition(position)} { intl.formatMessage({ id:"app.dashboard.position"})}</span>;
        }
        return <span>- { intl.formatMessage({ id:"app.dashboard.position"})}</span>;
    }*/

    const getClass = (gp) => {
        var c = "gp";
        if (gp.active){
            c+=" active ";
        }
        //if (getDone(gp)) {
        if (gp.done) {
            c += " done ";             
        }
        if (gp.current){
            c+=" current ";
        }
        if (gp.pending){
            c+=" pending ";
        }
        return c;
    }
   
    const onImageError = (e) => {        
        e.target.src = Spain
    }

    const closeSpecialModal = () =>{        
        setShowModalSpecial(false);
        localStorage.setItem("SPECIAL", special);
    }

    const imagePath = process.env.NODE_ENV === "production"?"/":process.env.PUBLIC_URL;

    return <main className="quizes">
        {showModalDelete?<ModalDelete />:null}        
        {showModalSpecial?<ModalSpecial close={ closeSpecialModal } id={special} />:null}        
        { (loading === true )?<div className="spinner-wrapper">< div className="spinner"/></div>:null }
        <AnimatePresence>
            {gps!==null&&!showModalSpecial?<>
                <motion.div animate={{ opacity:1, scale:1, x:0, transition: { duration: 0.7, type: "spring", bounce: 0.5 }}} initial={{opacity:0, scale:1, x: -200}} exit={{opacity:0, scale:1, transition: { duration: 0.3 } }} layout >
                    <h2>{ intl.formatMessage({ id:"app.dashboard.takepart"})}</h2>
                    <p>{ intl.formatMessage({ id:"app.dashboard.select"})}</p>
                    <p>{ intl.formatMessage({ id:"app.dashboard.invite"})}</p>
                    <div className="gps">
                        {gps.map( (gp,index) =>{
                            return (
                            <motion.div key={"gp-"+ gp.idgran_premio} animate={{ opacity:1, scale:1, x:0, transition: { delay: index*0.1, duration: 0.7, type: "spring", bounce: 0 }}} initial={{opacity:0, scale:1, x: -1200}} exit={{opacity:0, scale:1, transition: { duration: 0.3 } }} layout className={ getClass(gp)}>
                            <div onClick={(event)=>{ if (gp.active){navigate("/quiz/"+gp.idgran_premio, {state:{goToFinish:gp.done}}) } else { event.preventDefault();} }} 
                            key={"gp-"+ gp.idgran_premio  }>                                
                                <div className="top">
                                    <div className="left">                                
                                        <img 
                                            src={imagePath+"images/flags/"+gp.icon} 
                                            onError={onImageError}
                                        />
                                    </div>
                                    <div className="right">
                                        {gp.special==="1"?<img src={Trophy}/>:null}
                                        {gp.current?<span>{ intl.formatMessage({ id:"app.dashboard.live"})}</span>:<span>&nbsp;</span>}    
                                    </div>
                                </div>
                                <h2>{props.user.id.idioma.toUpperCase()==="EN"?gp.name.toUpperCase():props.user.id.idioma.toUpperCase()==="PL"?intl.formatMessage({ id:"app.country."+gp.name.toLowerCase()}).toUpperCase():gp.nombre.toUpperCase()}</h2>
                                {gp.active && !gp.done?<div className="available"><span>{ intl.formatMessage({ id:"app.dashboard.available"})}</span>{gp.pending?<img className="pending" src={pending}/>:null}</div>:null}
                                {/*getDone(gp)?<div className="position">{getGPPosition(gp)}<img src={hits} /></div>:null*/}
                                {gp.done?<div className="position"><span>{ intl.formatMessage({ id:"app.dashboard.finished"})}</span> <img src={hits} /></div>:null}
                                {!gp.active?<div className='position'>{getDate(gp)}{gp.next ? <img src={delay}/> : null}</div>:null}
                                
                            </div>
                            </motion.div>)
                        })
                        }
                    </div>
            </motion.div></>:null}
        </AnimatePresence>
    </main>
}
const mapStateToprops = (state)=>{  
  return {...state};
}
export default connect(mapStateToprops,{setUser:setUser})(Quizes)