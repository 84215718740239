import React from 'react'
import { useIntl } from 'react-intl'

const HowQuizWork = () => {
    const intl = useIntl() 

    return  <>
                <p>{intl.formatMessage({ id: "app.faq.howquizwork.text" })}</p>
                <ul>
                    <li><strong>{intl.formatMessage({ id: "app.faq.howquizwork.boosttitle" })}</strong> {intl.formatMessage({ id: "app.faq.howquizwork.boost" })}</li>
                    <li><strong>{intl.formatMessage({ id: "app.faq.howquizwork.pitstoptitle" })}</strong> {intl.formatMessage({ id: "app.faq.howquizwork.pitstop" })}</li>
                    <li><strong>{intl.formatMessage({ id: "app.faq.howquizwork.teamradiotitle" })}</strong> {intl.formatMessage({ id: "app.faq.howquizwork.teamradio" })}</li>
                </ul>
            </>
}
export default HowQuizWork
