import React from 'react'
//import { useNavigate } from 'react-router-dom'
//import {motion} from "framer-motion"
//import {FormattedMessage } from 'react-intl';
//import Success from '../../assets/images/success.png'
//import useWindowSize from '../hooks/useWindowSize'
import Teams from '../../assets/images/teams.svg'

const Modal = (props) => {
  
  //const size = useWindowSize();
  //const tablet = 768;
  //const y = size.width > tablet?"-50%":"0";
  //const x = size.width > tablet?"-50%":"0";

  return <div className="modal modalOK modalF1 modalTest">
    {/*<motion.div animate={{ opacity:1, x: x, y: y, transition: { duration: 0.5, type: "spring", bounce: 0.5 }}} initial={{opacity:0, x: -200, y: y}} exit={{opacity:0, x:-600, transition: { duration: 0.2 } }} layout className="modalWrapper">*/}
    <div className="modalWrapper">
      <div className="modalContent">
            <div className="right">
              <div className="title">
                <img src={Teams} alt=""/>
                <h2>
                  {props.title}
                  <br/>              
                  {props.title2} 
                </h2>
              </div>
                <p>
                  {props.text}
                  <br/>
                  {props.text2}
                </p>
                <div className="buttons">
                  <button onClick={()=>{props.close()}} className="btn cancel"> 
                    {props.cancelButtonText}
                  </button>
                  <button onClick={()=>{props.join()}} className="btn"> 
                    {props.buttonText}
                  </button>
                </div>
            </div>
      </div>     
      </div>
    {/*</motion.div>*/}
  </div>
}

export default Modal