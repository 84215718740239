import React, { useState, useEffect} from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import Button from '../common/Button'

const TeamInfo = (props) => {
    const [existTeam, setExistTeam] = useState(false)
    let navigate = useNavigate();
    const intl = useIntl()

       
    const instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,		
        timeout: 16000,
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('JWT') }
    });

    //en teams/detail
    //sino tengo equipo devuelve un 404 
    useEffect(()=>{ 
		instance.get("api/team/details").then( (/*response*/)=>{
			//console.log(response.data);	
            setExistTeam(true)
		
		}).catch( (error)=>{
			console.log(error);
            if(error.status === 404){
				setExistTeam(false)
			}
		})
	}, []);

    var textButtonTeam=intl.formatMessage({ id: 'app.quiz.share.createteambutton' })
    if(existTeam){
        textButtonTeam=intl.formatMessage({ id: 'app.quiz.share.teambutton' })
    }
    return  <>
                <div className={props.congrats ? "titleTeamCongrats" : "titleTeam"}>{intl.formatMessage({ id: 'app.quiz.share.teamtitle' })}</div>
                <div className="textTeam">{intl.formatMessage({ id: 'app.quiz.share.teamtext' })}</div>
                <Button color="white" text={textButtonTeam} clicked={()=>navigate('/teams') }/>
            </>
}
export default TeamInfo