import React/*, {useState, /*useEffect/*, useContext}*/ from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const CircleTimer = (props) => {
	const colorBase = "#CEDEE7";
	const colorAccent = "#EC0000";
	//console.log(props.time)

	const onFinish = () => {
		props.onFinish();
	}
	const renderTime = () => {
		return (
			<span className="circleNumber">{props.number}</span>
		);
	};
  
	return (
			<>
				<CountdownCircleTimer
					key={props.key}
					isPlaying
					initialRemainingTime={props.time}
					duration={props.duration}
					size="32"
					strokeWidth="5"
					strokeLinecap="square"
					colors={[colorBase]}
					trailColor={[colorAccent]}
					onComplete={onFinish}>
						{renderTime}
					</CountdownCircleTimer>
			</>
	);
};

export default CircleTimer;
