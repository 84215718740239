//Action creator
export const setUser = (user) => {
    //return an Action
    return {
        type: 'USER',
        payload: user
    };
};

//Action creator
export const setPoll = (poll) => {
    //return an Action
    return {
        type: 'POLL',
        payload: poll
    };
};