import axios from 'axios'

export const TeamAPI = {
	get,  
    getAll,  
    add,
    expired,
};

async function callAdd(code){
    var t = null;
    var instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: 16000,
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('JWT') }
    });

    await instance.get('api/team/add/'+code, {})
        .then(
            (data)=>{
                t = data.data.data;
            }
        )
        .catch( ()=>{
            t = null;
        })
    return t;
}

async function call(){
    var t = null;
    var instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: 16000,
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('JWT') }
    });

    await instance.get('api/team/myteams', {})
        .then(
            (data)=>{
                t = data.data.data;
            }
        )
        .catch( ()=>{
            t = null;
        })
    return t;
}

async function callAll(){
    var t = null;
    var instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: 16000,
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('JWT') }
    });

    await instance.get('api/team/all', {})
        .then(
            (data)=>{
                t = data.data.data;
            }
        )
        .catch( ()=>{
            t = null;
        })
    return t;
}

async function callExpired(){
    var t = null;
    var instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: 16000,
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('JWT') }
    });

    await instance.get('api/team/expired', {})
        .then(
            (data)=>{
                t = data.data;
            }
        )
        .catch( ()=>{
            t = true;
        })
    return t;
}

async function getAll(){    
    var retorno = await callAll();
    return retorno;
}

async function get(){    
    var retorno = await call();
    return retorno;
}

async function add(code){    
    var retorno = await callAdd(code);
    return retorno;
}

async function expired(){    
    var retorno = await callExpired();
    return retorno;
}
