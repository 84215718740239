import React from 'react'
import { Link } from 'react-router-dom'
//import logof1 from '../../assets/images/f1-logo.svg'
//import logosantander from '../../assets/images/santander-logo.svg'
import left from '../../assets/images/chevron-left.svg'
import logos from '../../assets/images/mis-logos.svg'

const HeaderQuiz = (props) => { 

    return <><header className="header header-quiz">
      <div className="container">
        <div className="flex">
          <div className="left">
            <span onClick={() => props.onClickBack()}>
                <img src={left} className="logo_" alt="Back"/>
            </span>
          </div>          
          <div className="right">
            <Link to="/" className="link-home">
              {/*<div className="logo">
                <img src={logof1} className="logo" alt="F1" />
              </div>
              <div className="logo">
                <img src={logosantander} className="logo" alt="Banco Santander" />
              </div>*/}
              <div className="logo">
                <img src={logos} className="logo" alt="Banco Santander F1 " />
              </div>
            </Link>         
          </div>          
        </div>
      </div>
    </header>
    </>
}
export default HeaderQuiz