import React from 'react'
import { Link } from 'react-router-dom'
import Casco from './Casco'
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useIntl } from 'react-intl'
import chevronRight from '../../assets/images/chevron-right.svg'

const Profile = (props) => {
    const intl = useIntl();
    const colorBase = "#E70101";
	const colorAccent = "#CCCCCC";
    const profileComplete = 6; 
    const profileCompleted = props.user.profileCompleted || 0;

    const showCompleted = () => {
        return (profileComplete === profileCompleted);
    }

    const doNotShow = () => {
        return  (props.view==="header-view" &&  profileComplete === profileCompleted ); 
    }
    
    return <>    
    {(doNotShow())?null:
    (showCompleted())?
    <><div className={ "profile-component completed "+ props.view}>
        <div className="left">            
            <Casco className="casco big" id={props.user.id} nick={props.user.nickname} />
        </div>
        <div className="right">
            <p>{props.user.nickname}</p>
        </div>
    </div></>:
    <div className={ "profile-component "+ props.view}>
        <div className="left">            
            <CountdownCircleTimer
                key={props.profileCompleted}
                isPlaying={false}
                initialRemainingTime={profileCompleted}
                duration={profileComplete}
                size="79"
                strokeWidth={7}
                trailStrokeWidth={7}            
                colors={[colorBase]}
                trailColor={[colorAccent]}
                rotation="counterclockwise"
            >            
            </CountdownCircleTimer>
            <Casco className="casco black-white" id={props.user.id} nick={props.user.nickname} />
        </div>
        <div className="right">
            {(props.view==="page-view")?
                <p>{props.user.nickname}</p> 
                :(props.view==="header-view")?<p>{ intl.formatMessage({ id:"app.profile.complete"})}</p>
                :<p>{ intl.formatMessage({ id:"app.profile.myprofile"})}</p>
            }           
            <div className="progress-bar">
                <div className="bar">
                    <div className={ "progress progress-" + profileCompleted }></div>
                </div>
                <span>{profileCompleted}/{profileComplete}</span>
            </div>
            {props.view==="page-view"?<p className="small">{ intl.formatMessage({ id:"app.profile.complete"})}</p>:null}
        </div>
        <div className="cta">
            <Link className="btn btnPrimary btnComplete" to="/profile">
                <img src={chevronRight} alt="complete" />
                <span>{ intl.formatMessage({ id:"app.profile.complete.text"})}</span>
            </Link>
        </div>
    </div>}
    </>
    
}
export default Profile