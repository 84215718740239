import React, { useState, useEffect /*, useContext*/ } from "react";
import { useNavigate } from "react-router-dom";
import Casco from "../common/Casco";
import Button from "../common/Button";
//import ShareClick from "../common/ShareClick";
import ShareButton from "../common/ShareButton";
import Question from "./Question";
import ShareOptions from "../Share/ShareOptions";
import { useIntl } from "react-intl";
//import { Link  } from 'react-router-dom'
import axios from "axios";
import HeaderGp from "./HeaderGp";
import ModalCustom from "../common/ModalCustom";
import { AnimatePresence } from 'framer-motion'
import iconHit from "../../assets/icons/hit.svg";
import iconErrors from "../../assets/icons/errors.svg";
import iconTimer from "../../assets/icons/timer.svg";
//import iconFlag from '../../assets/icons/flag.svg'
import iconShare from "../../assets/icons/share.svg";
import Trophy from "../../assets/images/trophy.svg";
import { connect } from "react-redux";

const Questions = (props) => {
  const [finish, setFinish] = useState(false);
  const [question, setQuestion] = useState(null);
  const [modal,setModal] = useState(false)
    const [url, setUrl] = useState(null)
  //const [share, setShare] = useState(false);
  const [goDouble, setGoDouble] = useState(false);
  const [gp, setGp] = useState(null);

  const intl = useIntl();
  const navigate = useNavigate();

  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 16000,
    headers: { Authorization: "Bearer " + localStorage.getItem("JWT") },
  });

  useEffect(() => {
    instance
      .get("/api/gps")
      .then((response) => {
        const gps = response.data.gps;

        for (var i = 0; i < gps.length; i++) {
          if (gps[i].idgran_premio === props.idgp) {
            setGp(gps[i]);
          }
        }
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  }, []);

  const copyLink = () => {
    //console.log(url)
    navigator.clipboard.writeText(url)
    setModal(false)
  }

  const finishedQuiz = (data) => {
    props.setFinished(true);
    setFinish(true);
    setQuestion(data);
    localStorage.removeItem("RANKING-DATE");
    localStorage.removeItem("RANKING");
    //change user ranking based on this info
    //console.log("change ranking based on:",  gp.idgran_premio, data);
  };

  /*const clickShare = () => {
    if (gp.current) {
      setGoDouble(true);
    } else {
      setShare(true);
    }
  };*/

  const clickViewRanking = () => {
    if (gp.special === "0") {
      navigate("/ranking", { state: { idgp: null, season: true } });
    } else if (gp.special === "1") {
      navigate("/ranking", {
        state: { idgp: gp.idgran_premio, season: false },
      });
    }
  };

  if (goDouble) {
    return (
      <>
        <ShareOptions gp={props.idgp} />
      </>
    );
  }

  const getPosition = (number, isDate = false) => {

    //console.log("posicion:", number);
    //var locale = props.user.id.idioma.toUpperCase() === "ES" ? "es-ES" : "en-EN";
    if (props.user.id.idioma.toUpperCase() === "EN") {

      if (number % 10 === 1) return <sup>st</sup>;
      if (number % 10 === 2) return <sup>nd</sup>;
      if (number % 10 === 3) return <sup>rd</sup>;
      return <sup>th</sup>;
    }
    if (props.user.id.idioma.toUpperCase() === "ES") {
      return isDate ? "" : "ª";
    }
    if (props.user.id.idioma.toUpperCase() === "PL") {
      return "";
    }
  };

  if (finish) {
    return <>
        {/*share ? (
          <ShareClick active={1} gp={gp.idgran_premio} setShare={setShare} />
        ) : null*/}
        <AnimatePresence>
                { modal && url !== null?
                    <ModalCustom title={intl.formatMessage({ id: 'app.quiz.share.modaltitle' })} text={url}
                    onClose={copyLink} btnTextContinue={intl.formatMessage({ id: 'app.quiz.share.copy' })} onContinue={copyLink} />
                : null}
        </AnimatePresence>
        <main className="quiz bg-finish">
          <div className="quiz-title">
            {/*<div className="left">
								<HeaderGp idgp={props.idgp} idioma={props.user.idioma}/>
							</div>*/}
            <div className="center">
              <h2 className="ready">
                {intl.formatMessage({ id: "app.quiz.finish" })}
              </h2>
            </div>
          </div>
          <div className={props.alreadyFinished?"finish-wrapper already-finished":"finish-wrapper first-time-finished"}>
              <div className="gp-head">
                <div className="country-wrapper">
                  <HeaderGp idgp={props.idgp} />
                </div>
                <div className="ready-wrapper">
                  <h2 className="ready">
                    {intl.formatMessage({ id: "app.quiz.finish" })}
                  </h2>
                </div>
              </div>
              <div className="finish-metrics">
                <div className="">
                  {gp !== null && gp.special === "0" ? (
                    <div className="season-msg">
                      <span className="">
                        {intl.formatMessage({ id: "app.quiz.rankedin" })}
                      </span>
                    </div>
                  ) : null}
                  <div className="finish-header">
                    <div className="finish-casco">
                      <Casco
                        id={props.user.id.idusuario}
                        nick={props.user.id.nickname}
                        className={props.user.id.profileCompleted===5?"casco":"casco black-white"}
                      ></Casco>
                    </div>
                    <div className="text-position">
                      {question.data.posicion}
                      {getPosition(question.data.posicion)}{" "}
                      {intl.formatMessage({ id: "app.quiz.position" })}
                    </div>
                  </div>
                  <div className="finish-content">
                    <span className="finish-icon-metrics">
                      <img src={iconHit} />
                    </span>
                    <span className="border-right-gray">
                      {question.data.totalAciertos}{" "}
                      {intl.formatMessage({ id: "app.quiz.hits" })}
                    </span>
                    <span className="finish-icon-metrics ml-7">
                      <img src={iconErrors} />
                    </span>
                    <span className="border-right-gray">
                      {parseInt(props.numeroPreguntas) -
                        parseInt(question.data.totalAciertos)}{" "}
                      {intl.formatMessage({ id: "app.quiz.errors" })}
                    </span>
                    <span className="finish-icon-metrics ml-7">
                      <img src={iconTimer} />
                    </span>
                    <span className="">
                      {question.data.totalTiempo}&apos;&apos;
                    </span>
                  </div>
                </div>
                {/*<div className='finish-flag'><img  src={iconFlag}/></div>*/}
              </div>
            
            {/*<div className='link-gp-ranking'>
										<div className='text-link-gp-ranking'>
											<Link to={"/ranking"}>
												{intl.formatMessage({ id: 'app.quiz.gpranking' })}
											</Link>
										</div>
										<div>
											<svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M9.55933 0.206572L15.3473 5.95985C15.424 6.0309 15.4661 6.13164 15.4626 6.23601C15.4641 6.34002 15.4224 6.44 15.3473 6.51217L9.55933 12.2655C9.49467 12.3321 9.40631 12.3707 9.31336 12.3728C9.21243 12.377 9.11451 12.338 9.04433 12.2655C8.97157 12.1934 8.93064 12.0954 8.93064 11.9931C8.93064 11.8909 8.97157 11.7928 9.04433 11.7208L14.1567 6.61114L0.473806 6.61189C0.264513 6.61194 0.0936634 6.44484 0.0894775 6.23601C0.0894775 6.02418 0.261547 5.85246 0.473806 5.85246L14.1474 5.85171L9.02127 0.751216C8.91098 0.658242 8.86221 0.511428 8.89499 0.371113C8.92777 0.230799 9.03658 0.120655 9.1767 0.085951C9.31682 0.0512467 9.46461 0.0978365 9.55933 0.206572Z" fill="#EC0000"/>
											</svg>
										</div>
						</div>*/}
            <div className="footer-finish">
            {gp !== null && gp.current ? (
              <div >
                <Button
                idButton="share-rrss"
                color="red big"
                icon={iconShare}
                clicked={() => {
                  setGoDouble(true);
                }}
                text={intl.formatMessage({ id: "app.quiz.sharedouble" })}
              />
              </div>
            ) : (
              
              <ShareButton
                idButton="share-rrss"
                active={gp !== null && gp.special ? 0 : 1}
                gp={gp !== null ? gp.idgran_premio : 1}
                color="red"
                icon={iconShare}
                text={intl.formatMessage({ id: "app.quiz.share" })}
                modalOutside={true}
                setUrl={setUrl}
                setModal={setModal}
              />
              
            )}
           
            <Button
              idButton="view-ranking"
              color="white"
              icon={Trophy}
              clicked={() => {
                clickViewRanking();
              }}
              text={
                  gp !== null && gp.special !== null ? (
                    intl.formatMessage({ id: "app.quiz.seasonranking" })
                  )
                  : 
                  (intl.formatMessage({ id: "app.quiz.gpranking" }))
              }
            />
            </div>
          </div>
          
        </main>
      </>
  }

  return (
    <>
      <main className="questions">
        <div className="questions-title">
          <div className="left">
            <HeaderGp idgp={props.idgp}  />
          </div>
          <div className="right">
            <h2 className="casco">
              <Casco
                id={props.user.id.idusuario}
                nick={props.user.id.nickname}
              ></Casco>
            </h2>
          </div>
        </div>
        <Question
          number={props.numberQuiz}
          numberQuestions={props.numeroPreguntas}
          user={props.user}
          quizFinished={finishedQuiz}
          showModal={props.showModal}
          setShowModal={props.setShowModal}
        />
      </main>
    </>
  );
};

//export default Questions
const mapStateToprops = (state) => {
  return { ...state };
};

export default connect(mapStateToprops)(Questions);
