import React from 'react'
import { connect } from 'react-redux'
import Close from '../../assets/icons/close.svg'
import Ad1 from '../../assets/images/ads/desktop/Escritorio_320x900_1.png'
import Ad2 from '../../assets/images/ads/desktop/Escritorio_320x900_2.png'
//import AdM from '../../assets/images/ads/mobile/Mobile_380x120_1.png'

const Ads = (props) => {

    const Pages_with_Ads = ["landing", "dashboard", "login", "team", "awards"];
    const [show, setShow] = React.useState(true); 
    //const [show, setShow] = React.useState(sessionStorage.getItem("ADS")===null);
    const AdM = process.env.REACT_APP_URL + "images/ads/mobile/Mobile_380x120_1.png"

    React.useEffect(() => {
        //console.log("current page", props.page);
        if (sessionStorage.getItem("ADS")===null){
            setShow(false);
        }
        //pages with Ads
        if (Pages_with_Ads.includes(props.page)){
            //console.log("DO NOT SHOW ADS");
            setShow(true); 
        }        
        //HIDE ALWAYS
        setShow(false); 
    },[]);

    const hide = () => {
        sessionStorage.setItem("ADS", 0);
        setShow(false);
    }

    return <>{show?<div className="ads" style={{ backgroundImage: `url(${AdM}`, backgroundSize: 'contain'}}>
        <div className="ad-wrapper" style={{ backgroundImage: `url(${Ad1}`}}>
            <div className="close">
                <button onClick={ () => { hide() } }><img src={Close}/></button>
            </div>
            {/*<p>ADVERTISING</p>*/}
        </div>  
        <div className="ad-wrapper right" style={{ backgroundImage: `url(${Ad2}`}}>            
            {/*<p>ADVERTISING</p>*/}
        </div>    
    </div>:null}
    </>
}
const mapStateToprops = (state)=>{    
    return {...state};  
}
export default connect(mapStateToprops)(Ads)