import React, { useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import axios from 'axios'
import ModalCustom from '../common/ModalCustom'
import { AnimatePresence } from 'framer-motion'
import Button from '../common/Button'
import iconShare from '../../assets/icons/share.svg'

const ShareButton = (props) => {
    const [url, setUrl] = useState(null)
    const [modal, setModal] = useState(false)
    const intl = useIntl()
    
    const instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,		
        timeout: 16000,
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('JWT') }
    });

    useEffect(()=>{ 
		instance.get("api/quiz/share/"+props.gp).then( (response)=>{				
            const lang = intl.locale.toUpperCase()==="EN"?"en/":"";
            const url = process.env.REACT_APP_SHARE_URL + lang + "gp/ranking/" + response.data.data.url;
            setUrl(url)
		
		}).catch( (error)=>{
			console.log(error);
		})
	}, []);

    const clickShare = () => {

        if (navigator.share) {
            
			navigator.share({
				title: 'Santander Formula 1',
				text: intl.formatMessage({ id: 'app.quiz.share' }),
				url: url,
			})
			.then(() => {                
                setTimeout( () => {
                    props.setBtShare(2)
                },1500);                
            })
			.catch((error) => console.log('Error sharing', error));
        }else{
            //modal para copiar url
            setModal(true)            
        }
    }

    const copyLink = () => {
        navigator.clipboard.writeText(url)
        props.setBtShare(2)
        setModal(false)
    }

    return  <>
                <AnimatePresence>
                { modal ?
                        <>
                        <ModalCustom title={intl.formatMessage({ id: 'app.quiz.share.modaltitle' })} text={url}
                        onClose={copyLink} btnTextContinue={intl.formatMessage({ id: 'app.quiz.share.copy' })} onContinue={copyLink}/>
                        </>
                    :
                        null
                }
                </AnimatePresence>
               {/*} <Button color="white" icon={iconShareRed} clicked={clickShare} text={intl.formatMessage({ id: 'app.quiz.share.sharebutton' })}/>*/}
                <Button color="red" icon={iconShare} clicked={clickShare} text={intl.formatMessage({ id: 'app.quiz.share.sharebutton' })}/>

            </>
}

export default ShareButton