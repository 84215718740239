import React, { useState } from 'react'
import { useIntl } from 'react-intl'
//import chevronDown from '../../assets/icons/chevrondown.png'
import chevron_Down from '../../assets/icons/chevrondown.svg'
import {motion, AnimatePresence} from "framer-motion"

const Accordion = (props) => {
    const [showContent, setShowContent] = useState(false)
    const intl = useIntl() 

    const clicked = () => {
        setShowContent(!showContent)
    }

    return  <>
            <AnimatePresence>
                <div  className='accordion'>
                    <button onClick={()=>clicked()}>
                        <span>{intl.formatMessage({ id: props.title })}</span>
                        <span><img src={chevron_Down} className={showContent?"up":""}></img></span>
                    </button>
                    {showContent?
                        <motion.div animate={{ height: "auto", transition:{ duration: 0.3 }}} initial={{ height:0}} exit={{ height: 0, transition: { duration: 0.3 } }} layout className='content'>
                            {props.content}
                        </motion.div>
                        : null
                    }
                </div>
            </AnimatePresence>
        </>

}

export default Accordion