import React from 'react'
import { useIntl } from 'react-intl'

const LeaveTeam = () => {
    const intl = useIntl() 

    return  <>
                <p>{intl.formatMessage({ id: "app.faq.caniliveteam.text" })}</p>
            </>
}
export default LeaveTeam

