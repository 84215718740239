import { React, useState, useEffect} from 'react'
import { useIntl } from 'react-intl'
import {useNavigate} from  "react-router-dom"
import { Perfil }  from '../Api/Perfil'
import Arrow from '../../assets/images/select-arrow.svg'
import Selector from './Selector'

const Form = (props) => {
    let navigate = useNavigate();
    const intl = useIntl();
    const [error, setError] = useState([]);    
    const [valores, setValores] = useState(null);
    const [valoresDB, setValoresDB] = useState(null);    
    const [preguntas, setPreguntas] = useState(null); 
    const [preguntaOpen, setPreguntaOpen] = useState(null); 

    useEffect(() => {
        Perfil.get().then(data=>{
            //console.log("get data", data);
            if (data!== null){
                setValores(data.data);
                setValoresDB(data.data);
                setPreguntas(data.preguntas);
            }else{
                navigate("/");
            }
        })
    }, []);   

    //Close Filters Dropdown on outer click
    useEffect(() => {
        const checkCloseSelector = e => {
        var closest = e.target.closest(".formGroup");//(".countries");
        if ( preguntaOpen && closest === null) {
            setPreguntaOpen(null);
        }
        }  
        document.addEventListener("mousedown", checkCloseSelector)
        return () => {        
        document.removeEventListener("mousedown", checkCloseSelector)          
        }
    }, [preguntaOpen])
    
    const getPerfilFromDB = () => {
        Perfil.get().then(data=>{
            //console.log("get data", data);
            if (data!== null){
                //setValores(data.data);
                setValoresDB(data.data);
            }
        })
    }

    const validate = (index=0) =>{
        var errors = []
        if (valores !== null){            
            //Se aplica a todos
            if (index > 0){
                if (valores["valor"+index].trim().length < 1){
                    errors.push({"name":"valor"+index, "msg": "app.register.error.mandatory"})
                }else{
                    if (valores["valor"+index].trim().length < 5){
                        errors.push({"name":"valor"+index, "msg": "app.register.error.minFive"})
                    }
                }
            }
            //Añadir validaciones extra por item...

            /*if (index===0 || index===1){
                if (valores["valor1"].trim().length < 1){
                    errors.push({"name":"valor1", "msg": "app.register.error.mandatory"})
                }else{
                    if (valores["valor1"].trim().length < 5){
                        errors.push({"name":"valor1", "msg": "app.register.error.minFive"})
                    }
                }
            }
            if (index===0 || index===2){
                if (valores["valor2"].trim().length < 1){
                    errors.push({"name":"valor2", "msg": "app.register.error.mandatory"})
                }else{
                    if (valores["valor2"].trim().length < 5){
                        errors.push({"name":"valor2", "msg": "app.register.error.minFive"})
                    }
                } 
            }*/
        }
        setError(errors)
        return errors.length===0
    }  
    
    const handleSubmit = () => {        
        //console.log("submit");
        if (validate()){
            //console.log("validated");
            //var info={valor1:"11"};
            Perfil.post(valores).then( data=>{
                //console.log(data);
                props.onUpdate(data);
            })
        }
    }

    const handleOneSubmit = (index) =>{
        if  (validate(index)){
            Perfil.onePost({key: "valor"+index, valor: valores["valor"+index]}).then(data =>{
                //console.log(data);
                props.onUpdate(data);
                getPerfilFromDB();
            });
        }
    }

    const hasError = (name) => {
        var found = false
        error.forEach(element => {
            if (element.name===name){
                found = true
            }
        })
        return found
    }

    const updateValor = (index, valor) =>{        
        var key ="valor"+index;
        setValores({...valores, [key]: valor});
    }

    useEffect( ()=>{
        //console.log("modified", valores);
    },[valores]);

    const selectValue = (value, index)=> {
        //console.log("selected", value, index);
        var key ="valor"+index;
        setValores({...valores, [key]: value});
        Perfil.onePost({key: "valor"+index, valor: value}).then(data =>{
            //console.log(data);
            props.onUpdate(data);
            getPerfilFromDB();
        });
        setPreguntaOpen(null);
    }

    const openClose = (index) => {
        if (preguntaOpen===index){
            setPreguntaOpen(null);
        } else {
            setPreguntaOpen(index);
        }
    }
    
    return <>    
        { !props.showOnlyEmpty?<h2><span>{ intl.formatMessage({ id:"app.profile.myprofile"})}</span></h2>:null}
        <form className="profile-form" style={{ marginBottom: "3rem" }} onSubmit={event => handleSubmit(event) }>           
            { valores !== null?<>
                { Object.entries(valores).map( (valor, index) =>{
                    if ( (props.showOnlyEmpty && valoresDB[ valor[0] ] ===null) || !props.showOnlyEmpty ){
                        if (preguntas[index]){
                            if (preguntas[index].respuestas.length > 1){
                                return <div key={"form-item-"+valor[0]} className="formGroup relative"> 
                                    <label>{ preguntas[index].pregunta }<span className="red">*</span></label>
                                    <input readOnly onClick={ ()=>{ openClose(index) } } type="text" style={{ cursor: "pointer"}} className="formControl" value={  valores!==null?valores[ valor[0] ]:"Select value"}/>
                                    <img  onClick={ ()=>{ openClose(index) } } style={{ cursor: "pointer"}} className={preguntaOpen===index?"selectArrow open":"selectArrow"} src={Arrow}/> 
                                    <Selector
                                        key={index+1}
                                        values = {preguntas[index].respuestas} 
                                        value = {valores!==null?valores[ valor[0] ]:""}                                         
                                        show = { preguntaOpen===index }
                                        onSelectValue={ (value) => selectValue(value, index+1) }
                                    />
                                </div>
                            }
                        }
                    return <div key={"form-item-"+valor[0]} className="formGroup relative"> 
                        <label>{ preguntas[index].pregunta } <span className="red">*</span></label>
                        <input  
                        onChange={event => { updateValor( index+1, event.target.value); } }
                        value={ valores!==null?valores[ valor[0] ]!==null?valores[ valor[0] ]:"":""}
                        onBlur={ ()=>{ handleOneSubmit(index+1)} }
                        onKeyDown={(e)=>{ if(e.key==="Enter"){ handleOneSubmit(index+1) } }}
                        className={ hasError("valor"+(index+1))?"formControl error":"formControl" }
                        minLength={5}
                        maxLength={100}
                        type="text" 
                        />
                        {error.map( item=>{
                            if (item.name==="valor"+(index+1)){
                                return (<label key={"error"+index+1} className="error">{intl.formatMessage({ id: item.msg })}</label>)
                            }else return null
                        }) }   
                    </div>} 
                    else {return null}
                })}
                </>:
            null}


        </form>
    </>
    
}
export default Form