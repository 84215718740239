import { React } from 'react'
import {motion} from "framer-motion"
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { setUser } from '../../actions'
import { useNavigate } from 'react-router-dom'

const CookiePanel = (props) => {     
    const intl = useIntl();  
    const navigate = useNavigate();

  return <div   className="modal modalOK modalF1 modalDelete modalSpecial">
    <motion.div animate={{ opacity:1, x: "-50%", y: "-50%",  transition: { duration: 0.5, type: "spring", bounce: 0.5 }}} initial={{opacity:0, x:"-150%", y:"-50%"}} exit={{opacity:0, transition: { duration: 0.2 } }}  className="modalWrapper">
      <div className="modalContent">
            <div className="right">
                <h2>
                {intl.formatMessage({ id: "app.modalspecial.title" })}
                </h2>               
                <p> {intl.formatMessage({ id: "app.modalspecial.text" })} </p>
                
                <div className="buttons flex">
                  <button onClick={()=>{ props.close(false) }} className="btn">{intl.formatMessage({ id: "app.modalspecial.close" })}</button>    
                  <button onClick={()=>{ navigate("/quiz/"+props.id, {state:{goToFinish:false}}) }} className="btn">{intl.formatMessage({ id: "app.menu.play"})}</button>
                </div>
            </div>            
      </div>     
    </motion.div>
  </div>
}
//export default ModalDelete
const mapStateToprops = (state)=>{  
  return {...state};
}
export default connect(mapStateToprops,{setUser:setUser})(CookiePanel)