import React from 'react'
import { useIntl } from 'react-intl'
import whoCanParticipate from '../../assets/images/whocanparticipate.png'
import whoCanParticipateDesktop from '../../assets/images/whocanparticipate-desktop.png'
import useWindowSize from '../hooks/useWindowSize'

const WhoParticipate = () => {
    const intl = useIntl() 
    const size = useWindowSize();
    const tablet = 768;
  
    return  <>
                <p>{intl.formatMessage({ id: "app.faq.whoparticipate.text" })}</p>
                <span><img src={size.width > tablet? whoCanParticipateDesktop:whoCanParticipate}/></span>
            </>
}
export default WhoParticipate
