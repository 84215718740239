import React from 'react'

const Casco = (props) => {
    const colors = [ '#ED1B18', '#990000', '#F4A82E', '#CB7400', '#F4A82E', '#FDD142', '#FCE9DF', '#C9AD37',  
    '#A55238',  '#B4D981', '#3791E2', '#19BBE0', '#DC294C', '#B6E2B7', '#B20D4E', '#D2F0F5', '#C0D58F', '#92A83C', 
    '#7F94F3', '#39CA3A', '#B62BB9', '#D18387', '#B25965', '#863E95', '#C63276', '#3D2799', '#6A4E95',
    '#1F8257', '#A3D5C1', '#359AD5'];


    const empty_colors = ['#9B9B9B', '#BBBBBB', '#C6C6C6', '#C9C9C9', '#444444'];

    const sdbm = str => {
        let arr = str.split('');
        return arr.reduce(
          (hashCode, currentVal) =>
            (hashCode = currentVal.charCodeAt(0) + (hashCode << 6) + (hashCode << 16) - hashCode),
          0
        );
      };

    const getColor = ( offset ) => {
        var userId = props.id;
        
        if (typeof props.id ==="undefined" || props.id===null){
            return empty_colors[offset];
        }
        if (isNaN(props.id)){
            userId = Math.abs(sdbm(props.id));
            //console.log("userId::", userId);
        }
        
        var d = 13;
        switch( offset){
            case 1: d=51; break; 
            case 2: d=53; break;
            case 3: d=17; break;
            case 4: d=111; break;
            case 5: d=13; break;
        }
        offset = (parseInt(userId) % d) + offset;
        var id = (parseInt(userId) + offset) % d;
        //return colors[((parseInt(props.id) + (parseInt(props.id)*offset) ) % (colors.length - 1))];
        return colors[  id % (colors.length - 1)];
    }

    return <>
        <svg className={props.className} width="32" height="34" viewBox="0 0 32 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_234_1095)">
                <path className="base" d="M16 0C4.8417 0 0 10.2225 0 13.1672C0 15.3541 0.808646 26.7565 2.12587 29.5741C3.4431 32.3916 12.0229 33.0528 16 33.0528C19.9771 33.0528 28.562 32.3916 29.8741 29.5741C31.1914 26.7565 32 15.3592 32 13.1672C32 10.2225 27.1583 0 16 0Z" fill={getColor(0)}/>
                <path className="top" d="M21.5738 1.60752C20.0735 0.844642 18.2222 0.33606 15.9997 0.33606C13.7772 0.33606 11.926 0.849728 10.4257 1.60752C11.926 2.37039 13.7772 6.59671 15.9997 6.59671C18.2222 6.59671 20.0786 2.37039 21.5738 1.60752Z" fill={getColor(1)}/>
                <path className="bottom" d="M15.9995 28.5516C11.9207 28.5516 3.49857 27.7989 1.01669 24.7322C1.32693 26.8428 1.69819 28.6635 2.1254 29.5739C3.44263 32.3914 12.0224 33.0526 15.9995 33.0526C19.9766 33.0526 28.5615 32.3914 29.8737 29.5739C30.3009 28.6584 30.6721 26.8428 30.9824 24.7322C28.4954 27.7989 20.0784 28.5516 15.9995 28.5516Z" fill={getColor(2)}/>
                <path className="bottom-detail" fillRule="evenodd" clipRule="evenodd" d="M29.5592 29.5023L28.6997 30.0871L29.3812 26.7966L30.2763 26.1914L29.5592 29.5023ZM27.7031 30.5708L26.8232 30.9421L27.3776 27.7228L28.3236 27.3159L27.7031 30.5708ZM25.2056 31.5214L25.9888 31.2519L26.4669 28.0732L25.6073 28.3784L25.2056 31.5214ZM2.44122 29.5028L3.30581 30.0876L2.61923 26.7971L1.72412 26.1919L2.44122 29.5028ZM5.17756 30.9421L4.3028 30.5708L3.67725 27.3159L4.62321 27.7228L5.17756 30.9421ZM6.01636 31.2519L6.79449 31.5214L6.39271 28.3784L5.5332 28.0732L6.01636 31.2519Z" fill={getColor(3)}/>
                <path className="lateral" fillRule="evenodd" clipRule="evenodd" d="M11.0869 11.6773C9.87142 8.05617 7.70995 5.22846 4.25159 5.00977C3.06659 6.24562 2.14097 7.57302 1.45947 8.81396C5.26777 8.0535 6.81112 9.36614 8.10215 10.4642C8.99558 11.224 9.76818 11.8811 11.0869 11.6773ZM20.9126 11.6773C22.1281 8.05617 24.2896 5.22846 27.7479 5.00977C28.9329 6.24562 29.8586 7.57302 30.5401 8.81396C26.7318 8.0535 25.1884 9.36613 23.8974 10.4642C23.004 11.224 22.2314 11.8811 20.9126 11.6773Z" fill={getColor(4)}/>
                <path className="visor" d="M15.9998 12.0835C5.73151 12.0835 1.75439 14.9722 1.75439 14.9722L2.29349 19.8801C2.44098 21.2227 3.39712 22.3365 4.70417 22.6874C6.85547 23.2672 10.6037 23.9792 16.0049 23.9792C21.406 23.9792 25.1543 23.2621 27.3056 22.6874C28.6126 22.3365 29.5688 21.2227 29.7162 19.8801L30.2553 14.9722C30.2553 14.9722 26.2731 12.0835 16.01 12.0835H15.9998Z" fill="black"/>
                <path className="visor_1" d="M6.43848 13.1261L7.01317 23.2062C7.55736 23.3079 8.1524 23.4096 8.7983 23.5011L8.188 12.7446C7.55227 12.8616 6.97249 12.9938 6.43848 13.1261Z" fill="#444444"/>
                <path className="visor_2" d="M9.00167 12.6024L9.62722 23.6082C9.91712 23.6438 10.2121 23.6743 10.5172 23.7099L9.87643 12.4702C9.57637 12.5109 9.28139 12.5567 8.99658 12.6024H9.00167Z" fill="#444444"/>
                <text x="11" y="22" className="text" fontSize="10px"  fill="#fff">{props.nick!==null?props.nick.substring(0,2).toUpperCase():"--"}</text>
            </g>
            {/*<defs>
                <clipPath id="clip0_234_1095">
                <rect width="32" height="33.0528" fill="white"/>
                </clipPath>
            </defs>*/}
        </svg>
    </>
}
export default Casco