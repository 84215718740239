import React, {useState} from 'react';
import {IntlProvider} from 'react-intl';
import Spanish from '../../lang/es.json';
import English from '../../lang/en.json';
//import Polish from '../../lang/pl.json';


export const Context = React.createContext();

//console.log("navigator.language", navigator.language);
const local = navigator.language;

let lang = (local.includes("es"))?Spanish:/*(local.includes("pl"))?Polish:*/English;
document.documentElement.lang = (local.toLowerCase().includes("es"))?"es":/*(local.includes("pl"))?"pl":*/"en";

const Wrapper = (props) => {
    const [locale, setLocale] = useState(local);
    const [messages, setMessages] = useState(lang);

    function selectLanguage(e) {
        //console.log("selectLanguage", e);
        const newLocale = e;//.target.value;
        setLocale(newLocale);
        if (newLocale.toLowerCase() === 'es') {
            setMessages(Spanish);
            document.documentElement.lang = 'es';
        } 
        if (newLocale.toLowerCase() === 'en'){
            setMessages(English);
            document.documentElement.lang = 'en';
        }
        /*if (newLocale.toLowerCase() === 'pl'){
            setMessages(Polish);
            document.documentElement.lang = 'pl';
        }*/
    }

    /*function getLanguage(){
        return locale;
    }*/

    return (
        <Context.Provider value = {{locale, selectLanguage}}>
            <IntlProvider messages={messages} locale={ local.includes("es")?"es":local.includes("pl")?"pl":"en" }>
                {props.children}
            </IntlProvider>
        </Context.Provider>

    );
}


export default Wrapper;