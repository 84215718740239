
import { React, useState, useEffect } from 'react'
import {FormattedMessage, useIntl } from 'react-intl'
import Api from '../Api/Api'
import CountrySelector from './CountrySelector'
import Arrow from '../../assets/images/select-arrow.svg'
import { useNavigate, Link } from 'react-router-dom'
import ModalSedes from './ModalSedes'
import ModalF1 from '../common/ModalF1'
import { validateEmail } from '../../utils/utils'

const Register = ({ onContinue, onBadMail, invalidMail, setTypeLogin }) => {
    const intl = useIntl()
    const [email, setEmail] = useState(invalidMail===null?"":invalidMail) 
    const [name, setName] = useState("") 
    const [surname, setSurname] = useState("") 
    const [nickname, setNickname] = useState("") 
    const [phone, setPhone] = useState("") 
    const [rut, setRut] = useState("") 
    const [birthdate, setBirthdate] = useState("") 
    const [country, setCountry] = useState({"name":"Spain", "alpha2Code":"ES", "languages":[{"iso639_1":"es"}]})
    const [terms, setTerms] = useState(false) 
    //const [comm, setComm] = useState(false) 
    const [comm2, setComm2] = useState(false) 
    const [showListCountries, setShowListCountries] = useState(false)    
    const [error, setError] = useState([])
    const [btnDisabled, setBtnDisabled] = useState(false)
    const [showSedes, setShowSedes] = useState(false)
    const [showModal, setShowModal] = useState(false)
    let navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0)  
    },[])  
    
    const validate = () =>{
        var errors = []
        if (email.trim().length < 1){
            errors.push({"name":"email", "msg": "app.register.error.mandatory"})
        }else {
            if (email.trim().length < 1){
                errors.push({"name":"email", "msg": "app.register.error.minTwo"})
            }
        }
        if (name.trim().length < 1){
            errors.push({"name":"name", "msg": "app.register.error.mandatory"})
        }else {
            if (name.trim().length < 1){
                errors.push({"name":"name", "msg": "app.register.error.minTwo"})
            }
        }
        if (surname.trim().length < 1){
            errors.push({"name":"surname", "msg": "app.register.error.mandatory"})
        }else{
            if (name.trim().length < 1){
                errors.push({"name":"surname", "msg": "app.register.error.minTwo"})
            }
        }
        if (birthdate.trim().length < 1){            
            errors.push({"name":"birthdate", "msg": "app.register.error.mandatory"})
        }else{
            if (calculateAge() < 18){
                errors.push({"name":"birthdate", "msg": "app.register.error.minor"})
            }
        }
        if (nickname.trim().length < 1){
            errors.push({"name":"nickname", "msg": "app.register.error.mandatory"})
        }else{
            if (nickname.trim().length < 5){
                errors.push({"name":"nickname", "msg": "app.register.error.minFive"})
            }
        }
        if (country.alpha2Code==="PL"){
            if (phone.trim().length < 1){
                errors.push({"name":"phone", "msg": "app.register.error.mandatory"})
            }else{
                if (phone.trim().length < 9){
                    errors.push({"name":"phone", "msg": "app.register.error.minNine"})
                }
            }
        }
        if (country.alpha2Code==="CL"){
            if (rut.trim().length > 1){                
                if (!validateRut(rut)){
                    errors.push({"name":"rut", "msg": "app.register.error.rut"})
                }
            }
        }
        if (terms===false){
            errors.push({"name":"terms", "msg": "app.register.error.mandatory"})
        }
        /*if (comm===false){
            errors.push({"name":"comm", "msg": "app.register.error.mandatory"})
        }*/
        /*if (comm2===false){
            errors.push({"name":"comm2", "msg": "app.register.error.mandatory"})
        }*/
        
        setError(errors)
        return errors.length===0
    }
    
    const validateRutCode = (T) =>{
        var M=0,S=1;
		for(;T;T=Math.floor(T/10))
			S=(S+T%10*(9-M++%6))%11;
		return S?S-1:'k';
    }

    const validateRut = (rutCompleto) =>{
        //console.log("validating rut...");
        if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutCompleto ))
			return false;
		var tmp 	= rutCompleto.split('-');
		var digv	= tmp[1]; 
		var rut 	= tmp[0];
		if ( digv == 'K' ) digv = 'k' ;
        var code = validateRutCode(rut);
        //console.log( code, digv, code===digv, code==digv );
		return (code == digv );
    }

    const getToday = () =>{
        var today = new Date()
        var dd = String(today.getDate()).padStart(2, '0')
        var mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
        var yyyy = today.getFullYear()

        today = yyyy+"-" + mm + '-' + dd
        return today
    }

    const handleSubmit = (event) => {

        var lang = "EN"
        country.languages.forEach( l=>{
            if (l.iso639_1==="es")
                lang = "ES"
            /*if (l.iso639_1==="pl")
                lang = "PL"*/
        })
        // validation inputs
        window.grecaptcha.ready(function() {
            window.grecaptcha.execute('6Le2hJwpAAAAAN8isoz4KOKyA_8mpiUCHbZlXhGR', {action: 'register'}).then(function(token) {
                if (validate() && validateEmail(email)){
                    setBtnDisabled(true)
                    Api.post('/api/register', { 
                        "email": email,
                        "nombre":name, 
                        "apellidos":surname,
                        "nickname": nickname,
                        "pais": country.name,
                        "phone": phone,
                        "rut": rut,
                        "fecha": birthdate, //pass with ts format: yyy-mm-dd
                        "terminos": terms?1:0, //0 or 1
                        "comunicaciones": 1, //comm?1:0, //0 or 1
                        "comunicaciones2": comm2?2:0, //when it was mandatory value was 1 naow: 0 or 2
                        "idioma": lang,//country.name==="Spain"?"es":"en",
                        "token": token
                        })
                    .then( (response) => {                 
                        if (response.data.status==201){
                            Api.post('/api/prelogin', { "email": email }, { validateStatus: false})
                            .then( (response) => { 
                                //console.log(response) 
                                if (response.data.status === 200){
                                    window.dataLayer.push({
                                        'event' : 'paso2',
                                        'status' : 'REGISTRO_EXITOSO'
                                    })
                                    onContinue(email, response.data.data.id, nickname)
                                    setTypeLogin(prevState => ({ ...prevState, code: true, register: false }))
                                }
                                if (response.data.status !== 200){
                                    //TODO: ShowError
                                    
                                    if (response.data.error==="Duplicate nickname"){
                                        var errors = []
                                        errors.push({"name":"nickname", "msg": "app.register.error.duplicateNickname"})        
                                        setError(errors)
                                    }
                                }
                            })                    
                        }
                        setBtnDisabled(false)
                    }).catch(error=>{
                        var errors = []
                        if (error.response.data.error=="Sorry, Register Closed"){
                            errors.push({"name":"nickname", "msg": "app.register.error.closed"})        
                            setError(errors)
                            setShowModal(true)
                        }
                        if (error.response.data.error==="Duplicate nickname"){                    
                            errors.push({"name":"nickname", "msg": "app.register.error.duplicateNickname"})        
                            setError(errors)
                        } else {                    
                            if (typeof error.response.data.error==='string'){
                                if (error.response.data.error.toUpperCase().includes("EMAIL")){
                                onBadMail(email)
                                }
                            }else{                        
                                for (const [key, value] of Object.entries(error.response.data.error)) {
                                    //console.log("error", key, value)
                                    errors.push({"name": key, "msg": value})
                                    setError(errors)
                                }
                            }
                        }
                        setBtnDisabled(false)
                    })
                }
            })
        })
        event.preventDefault()
    }

    const selectCountry = (country) => {
        //console.log("Register SelectCountry",country)  
        setCountry(country)
        setShowListCountries(false)
    }

    const calculateAge = () => { 
        var myBirthday = new Date(birthdate)
        var ageDifMs = Date.now() - myBirthday.getTime()
        var ageDate = new Date(ageDifMs) // miliseconds from epoch
        return Math.abs(ageDate.getUTCFullYear() - 1970)
    }

    const hasError = (name) => {
        var found = false
        error.forEach(element => {
            if (element.name===name){
                found = true
            }
        })
        return found
    }

    const baseleglesLink = () => {
        var f = "Santander-Formula1-Bases_Legales_EN.pdf"
        if (navigator.language.includes("en")){
            f = "Santander-Formula1-Bases_Legales_EN.pdf"
        }
        if (navigator.language.includes("es")){
            f = "Santander-Formula1-Bases_Legales_ES.pdf"            
        } 
        if (navigator.language.includes("pl")){
            f = "Santander-Formula1-Bases_Legales_PL.pdf"            
        } 
        return process.env.REACT_APP_URL + "images/bases-legales/"+f
    }
    

return <>
    {showSedes?<ModalSedes close={()=>setShowSedes(false)} buttonText={intl.formatMessage({id:"app.modalspecial.close"})} />:null}
    {showModal?<ModalF1 close={()=>{ setShowModal(false); navigate("/") }} buttonText={intl.formatMessage({id:"app.modalspecial.close"})} text={intl.formatMessage({id:"app.register.error.closed"})} />:null}
    <div className='register-title'>
        <h3 className="title">
            <FormattedMessage
                id = "app.register.title"
                defaultMessage="Create your driver"
            />
        </h3>
        <p>
            <FormattedMessage
            id = "app.register.text"
            defaultMessage="With your name and country we will personalize your experience."
            />
        </p>  
    </div>
    
    <div className='register-form'>
        <form onSubmit={event => handleSubmit(event) }>
            <div className="formGroup relative"> 
                <label>{ intl.formatMessage({ id: 'app.correo' }) } <span className="red">*</span></label>                           
                <input onChange={event => { setEmail(event.target.value)} } 
                className={ hasError("email")?"formControl error":"formControl" }
                minLength={5}
                maxLength={100}
                type="email" 
                /> 
                { invalidMail===null?null:
                <label className="error error-mail">{intl.formatMessage({ id: "app.register.error.invalidMail" })}</label>}

                {error.map( item=>{
                    if (item.name==="email"){
                        return (<label key="error1" className="error">{intl.formatMessage({ id: item.msg })}</label>)
                    }else return null
                }) }   
            </div>

            <div className="formGroup relative"> 
                <label>{ intl.formatMessage({ id: 'app.register.name' }) } <span className="red">*</span></label>                           
                <input onChange={event => { setName(event.target.value)} } 
                className={ hasError("name")?"formControl error":"formControl" }
                minLength={2}
                maxLength={20}
                type="text" 
                /> 
                {error.map( item=>{
                    if (item.name==="name"){
                        return (<label key="error2" className="error">{intl.formatMessage({ id: item.msg })}</label>)
                    }else return null
                }) }
                
            </div>
            <div className="formGroup relative">
                <label>{ intl.formatMessage({ id: 'app.register.surname' }) } <span className="red">*</span></label>                            
                <input onChange={event => { setSurname(event.target.value)} } 
                className={ hasError("surname")?"formControl error":"formControl" }
                minLength={2}
                maxLength={40}
                type="text"             
                /> 
                {error.map( item=>{
                    if (item.name==="surname"){
                        return (<label key="error3" className="error">{intl.formatMessage({ id: item.msg })}</label>)
                    }else return null
                }) }           
            </div>
            <div className="formGroup relative">  
                <label>{ intl.formatMessage({ id: 'app.register.birthdate' }) } <span className="red">*</span></label>                          
                <input onChange={event => { setBirthdate(event.target.value)} }             
                className={ hasError("birthdate")?"formControl error":"formControl" }
                type="date"
                min="1923-01-01" max={getToday()}            
                />  
                {error.map( item=>{
                    if (item.name==="birthdate"){
                        return (<label key="error4" className="error">{intl.formatMessage({ id: item.msg })}</label>)
                    }else return null
                }) }            
            </div>
            <div className="formGroup relative">
                <label>{ intl.formatMessage({ id: 'app.register.nickname' }) } <span className="red">*</span></label>
                <input onChange={event => { setNickname(event.target.value)} } 
                className={ hasError("nickname")?"formControl error":"formControl" }
                minLength={5}
                maxLength={24}
                type="text"             
                /> 
                {error.map( item=>{
                    if (item.name==="nickname"){
                        return (<label key="error5" className="error">{intl.formatMessage({ id: item.msg })}</label>)
                    }else return null
                }) }           
            </div>
            <div className="formGroup relative country-selector">
                <label>{ intl.formatMessage({ id: 'app.register.country' }) } <span className="red">*</span></label> 
                {country!== null?
                <div className="flag left">                
                    <img src={process.env.PUBLIC_URL+"images/more-flags/" + country.alpha2Code + ".svg"} alt={country.name} />
                </div>:null}                
                <input onClick={ ()=>{ setShowListCountries(!showListCountries) } } readOnly={true} value={ country!==null?country.name:'' } className="formControl" type="text" 
                placeholder={ intl.formatMessage({ id: 'app.register.country' }) } />   
                <img  onClick={ ()=>{ setShowListCountries(!showListCountries) } } className={showListCountries?"selectArrow open":"selectArrow"} src={Arrow}/>            
                <CountrySelector
                    country={/*"es"*/country}
                    show={showListCountries}
                    onSelectCountry={ (country) => selectCountry(country) }
                ></CountrySelector>
            </div>
            {country.alpha2Code==="PL"?
            <div className="formGroup relative">
                <label>{ intl.formatMessage({ id: 'app.register.phone' }) } <span className="red">*</span></label>
                <input onChange={event => { if (!isNaN(event.target.value)) {setPhone(event.target.value)}} } 
                className={ hasError("phone")?"formControl error":"formControl" }
                minLength={9}
                maxLength={11}
                value={phone}
                type="tel"             
                /> 
                {error.map( item=>{
                    if (item.name==="phone"){
                        return (<label key="error7" className="error">{intl.formatMessage({ id: item.msg })}</label>)
                    }else return null
                }) }           
            </div>:null}
            {country.alpha2Code==="CL"?
            <div className="formGroup relative">
                <label>RUT{/* intl.formatMessage({ id: 'app.register.phone' }) */}</label>
                <input onChange={event => { setRut(event.target.value)} } 
                className={ hasError("rut")?"formControl error":"formControl" }
                minLength={9}
                maxLength={11}
                value={rut}
                type="text"
                placeholder='11111111-1'
                /> 
                {error.map( item=>{
                    if (item.name==="rut"){
                        return (<label key="error8" className="error">{intl.formatMessage({ id: item.msg })}</label>)
                    }else return null
                }) }           
            </div>:null}
            <div className="formGroup flex relative cb">                            
                <input id="terms" checked={terms} onChange={(event) => { setTerms(event.target.checked) }} 
                className="formControl" 
                type="checkbox" 
                placeholder={ "" } />            
                <label htmlFor='terms' className="checkbox">{ intl.formatMessage({ id: 'app.register.by' }) }
                &nbsp;<a href={ baseleglesLink() } target="_blank" rel="noreferrer" download="bases-legales.pdf">{ intl.formatMessage({ id: 'app.register.conditions' }) }</a>&nbsp;{ intl.formatMessage({ id: 'app.register.view' }) } 
                &nbsp;<Link to="/privacy-policy" target="_blank" rel="noopener noreferrer">{ intl.formatMessage({ id: 'app.register.privacy' }) }</Link>&nbsp;{ intl.formatMessage({ id: 'app.register.ofthepromo' }) }</label> 
                {error.map( item=>{
                    if (item.name==="terms"){
                        return (<label key="error6" className="error">{intl.formatMessage({ id: item.msg })}</label>)
                    }else return null
                }) } 
            </div>
            {/*<div className="formGroup flex relative">                            
                <input checked={comm} onChange={(event) => { setComm(event.target.checked)}} className="formControl" type="checkbox" 
                placeholder={ "" } />            
                <label className="checkbox">{ intl.formatMessage({ id: 'app.register.agree' }) }</label>
                {error.map( item=>{
                    if (item.name==="comm"){
                        return (<label key="error7" className="error">{intl.formatMessage({ id: item.msg })}</label>)
                    }else return null
                }) } 
            </div>*/}
            <div className="formGroup flex relative cb">                            
                <input id="comm2" checked={comm2} onChange={(event) => { setComm2(event.target.checked)}} className={hasError("comm2")?"formControl":"formControl"} type="checkbox" 
                placeholder={ "" } />            
                <label htmlFor='comm2' className="checkbox">
                    { intl.formatMessage({ id: 'app.register.universia' }) }

                    {
                        document.documentElement.lang !== 'pl' ?
                        <> 
                            <span>&nbsp;</span><a onClick={(event)=>{setShowSedes(true); event.preventDefault()}} href="https://www.bancosantander.es/buscador-oficinas-cajeros" target="_blank" rel="noreferrer">{ intl.formatMessage({ id: 'app.register.lista' }) }</a>
                        </>
                        : null
                    }
                    
                    { intl.formatMessage({ id: 'app.register.universia2' }) }
                    &nbsp;<Link to="/privacy-policy" target="_blank" rel="noopener noreferrer">{ intl.formatMessage({ id: 'app.register.privacy' }) }</Link>
                    &nbsp;{ intl.formatMessage({ id: 'app.register.ofthepromo' }) }

                    {
                        document.documentElement.lang === 'pl' ? 
                        <>
                            <span>&nbsp;</span><a onClick={(event)=>{setShowSedes(true); event.preventDefault()}} href="https://www.bancosantander.es/buscador-oficinas-cajeros" target="_blank" rel="noreferrer">{ intl.formatMessage({ id: 'app.register.lista' }) }</a>
                        </>
                        : null
                    }
                </label>
                {error.map( item=>{
                    if (item.name==="comm2"){
                        return (<label key="error8" className="error">{intl.formatMessage({ id: item.msg })}</label>)
                    }else return null
                }) } 
            </div>
            <button id="signup-start" className="btn btnPrimary"> <FormattedMessage
                id = "app.continue"
                defaultMessage="Continue"
                disabled={btnDisabled}
            /></button>
        </form>
    </div>
    </>
}
export default Register