import { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {useNavigate} from  "react-router-dom"
import {TeamAPI } from '../Api/TeamAPI'
//import Valentina from '../../assets/images/valentina.png'
//import James from '../../assets/images/james.png'
import Ann_Suki from '../../assets/images/Annsuki.jpeg'
import Fercho from '../../assets/images/fercho.png'
import Vicens from '../../assets/images/Vicens.jpg'
import UK from '../../assets/images/WTF1.jpg'
//import BR from '../../assets/images/br.png'
import Level from './Level'
import Up from '../../assets/images/chevron-up.svg'
import Modal from './Modal'

const TeamChoose = (props) => {
  const intl = useIntl();
  var navigate = useNavigate();
  const [teams, setTeams] = useState(null);
  const [open, setOpen] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [loading, setLoading] = useState(true);
  const levels = [
    [9, 10, 8, 10], //Ann Suki
    [8, 9, 7, 10], //Vicens
    [8.5, 9.5, 7.5, 11], //Fercho
    [8, 8, 6, 10], //WTF1
  ];

  useEffect(() => {
    props.setPage('team');
    TeamAPI.getAll().then( data => {
      //console.log(data);
      data.sort( function (item1, item2){
        if (item1.fit>item2.fit) return -1;
        return 1;
      })
      setTeams(data);
      setLoading(false);
    })
  }, []) 

  const handleOpen = (index) => {
    if (open===index){ 
      setOpen(-1)
    } else {
      setOpen(index);
    }
  }

  const handleJoin = (event, team) => {
    //console.log("join team",event, team);
    setSelectedTeam(team);
    setShowModal(true);
  }

  const completeJoin = ()=>{
    //Pass selectedTeam to API
    TeamAPI.add(selectedTeam.codigo).then( data => {
      if (data !== null){
        navigate("/team");
      }
    });
  }

  const getOnlyName = (name) => {
    var arr = name.split(" ");
    return arr[0];
  }

  const getOnlySurname= (name) =>{
    console.log(name);
    var arr = name.split(" ");
    if (arr.length>1) return arr[1];
    else return arr[0];
  }

  const getInfluencerName = () =>{
    if (selectedTeam!==null){
      if (parseInt(selectedTeam.idequipo)===1){
        return getOnlySurname(selectedTeam.nombre);
      }else{
        return getOnlyName(selectedTeam.nombre);
      }
    }
  }

	return <main className="test team-choose">
    <div className="container">
      <div className="containerWrapper">
      { (loading === true )?<div className="spinner-wrapper">< div className="spinner"/></div>:
      <>

      {showModal?
        <Modal 
          title={ intl.formatMessage({ id:"app.test.modal.title"})} 
          title2={ intl.formatMessage({ id:"app.test.modal.title2"}, {influencer: getInfluencerName(selectedTeam.nombre) })}
          text={ intl.formatMessage({ id:"app.test.modal.text"})}  
          text2={ intl.formatMessage({ id:"app.test.modal.text2"})} 
          cancelButtonText = { intl.formatMessage({ id:"app.test.modal.button.cancel"})} 
          buttonText={ intl.formatMessage({ id:"app.test.modal.button.ok"})}
          close={ ()=> {setShowModal(false)} }
          join={ ()=>{ completeJoin() } }/>
        :null}

      <h2><FormattedMessage id = "app.test.choose" defaultMessage="Choose your team" /></h2>
      <p><FormattedMessage id = "app.test.play" defaultMessage="Play the championship with the team manager of your choice. First you will see the team with which you have the most affinity, followed by the other teams in order of your compatibility percentage." /></p>
    { teams !== null?
    <div className="cards">
    {teams.map( (t, index) => {
        return <div className="card team-card" key={t.idequipo}>
          {/* TODO: la imagen debe ser más grande */}
          <div className="top">
            {parseInt(t.idequipo)===1? <img src={Ann_Suki} />:null}
            {parseInt(t.idequipo)===2? <img src={Vicens} />:null}
            {parseInt(t.idequipo)===3? <img src={Fercho} />:null}
            {parseInt(t.idequipo)===4? <img src={UK} />:null}
            {/*parseInt(t.idequipo)===5? <img src={BR} />:null*/}
            <p><FormattedMessage id = "app.test.fit" defaultMessage="Fit" /> <span>{t.fit}%</span></p>
          </div>

          <div className="info">
            <h3><FormattedMessage id = "app.test.team"/> {t.nombre}</h3>
            <p><FormattedMessage id = {"app.test.team"+t.idequipo+".text"} defaultMessage="" />  </p>
            <button className="btn button-red" onClick={(e) => { handleJoin(e, t); }}>
              <FormattedMessage id="app.test.join" defaultMessage="Join team" values={{ influencer: t.idequipo==='1'?getOnlySurname(t.nombre):getOnlyName(t.nombre) }}></FormattedMessage>
            </button>
            <div className={index===open?"bottom open":"bottom"}>
              {/*index===open?<>*/}
              <Level level={levels[parseInt(t.idequipo)-1][0]} title={ intl.formatMessage({ id:"app.test.velocity"})} />
              <Level level={levels[parseInt(t.idequipo)-1][1]} title={ intl.formatMessage({ id:"app.test.F1Knowledge"})} />
              <Level level={levels[parseInt(t.idequipo)-1][2]} title={ intl.formatMessage({ id:"app.test.STEMKnowledge"})} />
              <Level level={levels[parseInt(t.idequipo)-1][3]} title={ intl.formatMessage({ id:"app.test.competitiveness"})} />
              {/*</>:null*/}
              <button onClick={ ()=>{ handleOpen(index); }} className={index===open?"show":"show closed"}><img src={Up} /></button>
            </div>
          </div>
        </div>
    })}
    </div>
    :null}
    </>}
    </div>
   </div>
	</main>
}

export default TeamChoose