import { React, useState } from 'react'
import { Link } from 'react-router-dom'
import { useIntl, FormattedMessage } from 'react-intl'


const ModalCheck = (props) => {  
    const intl = useIntl();
    const [disabled, setDisabled]  = useState(true);

    const handleCheck = (event) => {
        if (event.target.checked){
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }
  

  return <div className="modal modalOK modalF1 modalDelete">
    <div className="modalWrapper">
      <div className="modalContent">
            <div className="right">
                <h2>
                 { intl.formatMessage({ id:"app.profile.modal.check.title"})}                 
                </h2> 
                    <div style={{ position: "relative", marginBottom: "2rem", maxHeight: "24rem",  overflowY: "auto" }}>
                        <input onChange={ (event)=>{ handleCheck(event);  } } type="checkbox" id="cb"  style={{ position: "absolute", left:0, top:"1px", border: "1px solid #333" }} />
                        <label style={{ color: "#333", textAlign: "left", display:"block", paddingLeft: "3rem" }} className="cb multiple" htmlFor="cb">
                            <span>
                                {/* intl.formatMessage({ id:"app.profile.modal.check.text"})*/}
                                <FormattedMessage id = 'app.profile.modal.check.text' values={{ br: <br /> }} /> 
                                &nbsp;<Link to="/privacy-policy" target="_blank" rel="noopener noreferrer">{ intl.formatMessage({ id: 'app.register.privacy' }) }</Link>                                
                            </span>
                        </label>
                    </div>
                    <div className="buttons" style={{ justifyContent: "flex-end"}} >
                        <button onClick={()=>{ props.close(false)}} className="btn negative"> 
                            { intl.formatMessage({ id:"app.profile.modal.check.ignore"})}
                        </button>
                        <button disabled={disabled} onClick={()=>{ setDisabled(true); props.send()}} className="btn"> 
                            { intl.formatMessage({ id:"app.profile.modal.check.consent"})}
                        </button>
                    </div>
               
            </div>
      </div>     
    </div>
  </div>
}

export default ModalCheck