import { React, useState } from 'react'
import {motion} from "framer-motion"
import { useIntl } from 'react-intl'
//import useWindowSize from '../hooks/useWindowSize'

const ModalDelete = (props) => {  
    const intl = useIntl();
    const [disabled, setDisabled]  =useState(false);
    //const size = useWindowSize();
    //const tablet = 768;
    //const y = size.width > tablet?"-50%":"0";
    //const x = size.width > tablet?"-50%":"0";

  return <div   className="modal modalOK modalF1 modalDelete">
    <motion.div animate={{ opacity:1, x:"-50%", y:"-50%", transition: { duration: 0.5, type: "spring", bounce: 0.5 }}} initial={{opacity:0, x: "-150%", y:"-50%"}} exit={{opacity:0, x:"150%", y:"-50%", transition: { duration: 0.3 } }}  className="modalWrapper">
      <div className="modalContent">
            <div className="right">
                <h2>
                 { intl.formatMessage({ id:"app.profile.remove"})}      
                </h2>
                {props.sent?<>
                    <p> { intl.formatMessage({ id:"app.profile.amail"})}</p>
                    <div className="buttons">
                        <button onClick={()=>{props.close(false)}} className="btn"> 
                        { intl.formatMessage({ id:"app.continue"})}
                        </button>                        
                    </div>
                    </>: 
                <>
                    <p>{ intl.formatMessage({ id:"app.profile.thisaction"})}</p>
                    <div className="buttons">
                        <button disabled={disabled} onClick={()=>{ props.close(false)}} className="btn"> 
                            { intl.formatMessage({ id:"app.cancel"})}
                        </button>
                        <button disabled={disabled} onClick={()=>{ setDisabled(true); props.send()}} className="btn negative"> 
                            { intl.formatMessage({ id:"app.continue"})}
                        </button>
                    </div>
                </>
                }
            </div>
      </div>     
    </motion.div>
  </div>
}

export default ModalDelete