import { React, useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { useLocation, useNavigate  } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { AnimatePresence } from "framer-motion"
import { connect } from 'react-redux'
import { setUser } from '../actions'
import { Context } from "./lang/Wrapper"
import Email from './Login/Email'
import Code from './Login/Code'
import Register from './Login/Register'
import ModalF1 from './common/ModalF1'
import ModalRegister from './common/ModalRegister'
import Intro from './Login/Intro'

const Login = (props) => {
    let { state } = useLocation()
    const intl = useIntl()
    const context = useContext(Context)
    let navigate = useNavigate()    
    const [loading, setLoading] = useState(true)      
    var search = useLocation().search        
    const [userId, setUserId] = useState(null)
    const [userEmail, setUserEmail] = useState(null)
    const [userNickname, setUserNickname] = useState(null)
    const [invalidMail, setInvalidMail] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [showModalBlocked, setShowModalBlocked] = useState(false)
    const [showModalRegister, setShowModalRegister] = useState(false);
    const [typeLogin, setTypeLogin] = useState({
        intro: true,
        email: false,
        code: false,
        register: false
    })

    var instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: 16000,
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('JWT') }
      })
    
    const getCookie = (name) => {
        let value = `; ${document.cookie}`;
        let parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    const setCookie = (name, value) => {
        document.cookie = `${name}=${value}; path=/; max-age=${60 * 60 * 24 * 365};`;
    }

    useEffect(() => {  
        //console.log( "blocked?", state!==null?state.blocked: "no-state")       
        window.scrollTo(0, 0)  
        props.setPage('login')
        //const code = new URLSearchParams(search).get('team')
        const lang = new URLSearchParams(search).get('lang')
        var goToDashboard = true
        if (lang === "en") {context.selectLanguage(lang)}
        /*if (code !== '' && code !== null){      
            localStorage.setItem("F1-TEAM", code)
            goToDashboard = false
            navigate("/welcome")        
        } */   
        //if token in localStorage try access APi with it to avoid re-login...
        if (localStorage.getItem("JWT") !== null){
            instance.get('/api/user')
            .then( (response) =>{
                props.setUser({"id": response.data.user})           
                if (goToDashboard) {
                    navigate("/dashboard")
                }
            })
            .catch( (/*error*/) =>{                
                setLoading(false)                                      
            })        
        } else {
            setLoading(false)
            if (state !== null){
                if (state.blocked==="no-auth"){                    
                    //setTimeout( ()=> setShowModalBlocked(true), 500)                    
                }
            }            
            console.log("save cookie AlertRegister");
            //if (localStorage.getItem("AlertRegister")===null){
            if (getCookie("AlertRegister") !== "1"){
                localStorage.setItem("AlertRegister", "1");
                setCookie("AlertRegister", "1");
                setShowModalRegister(true);                        
            } 
        }
       
    },[])   
    
    const setEmail = (mail, id) =>{        
        setUserId(id)
        setUserEmail(mail)
    }
    const setNickname = (nickname) =>{        
        setUserNickname(nickname)
    }
    const setEmailRegister = (mail) =>{        
        setUserEmail(mail)
    }
    const setUser = (id) =>{
        props.setUser({"id":id})
    }
    const setBadMail = (mail) =>{
        setUserEmail(null)
        setInvalidMail(mail)
    }
    const getClasses = () =>{
        if (userId === null && userEmail !== null){
            return "home login register"
        }
        if (userId !== null && userEmail !== null){
            return "home login code"
        }
        return "home login"
    }
   
    return <>
        <AnimatePresence>
            {(showModalBlocked && props.user===null)?<ModalF1 text={intl.formatMessage({ id: "app.login.needto" })} close={ ()=> setShowModalBlocked(false)} buttonText={ intl.formatMessage({ id: 'app.entrar' }) } />:null}
            {showModal?<ModalF1 text={ intl.formatMessage({ id:"app.code.resend"})} close={ ()=> setShowModal(false)} buttonText={ intl.formatMessage({ id: 'app.entrar' }) } />:null}            
        </AnimatePresence>
        {showModalRegister?<ModalRegister text={ intl.formatMessage({ id:"app.modal.regsiter.important"})}  
                                        close={ ()=> setShowModalRegister(false)} 
                                        go= { ()=>{ setTypeLogin(prevState => ({ ...prevState, intro: false, register: true }));
                                                    setShowModalRegister(false) }
                                            }
                                        buttonText={ intl.formatMessage({ id:"app.login.createaccount" }) } />
        :null}

        <main className={getClasses()}>
        <div className="intro">
            <div className="container" style={{ padding: "0" }}>
                <div className="content">
                    <div className="colRight">                
                        { (loading === true )?<div className="spinner-wrapper">< div className="spinner"/></div>:null }

                        { typeLogin.intro ?
                            <Intro setTypeLogin={setTypeLogin} />
                            :null
                        }
                        
                        { (userId===null && userEmail===null && typeLogin.email)?
                            <Email 
                                onRegister={ (mail) => setEmailRegister(mail) } 
                                onContinue={ (mail, id) => setEmail(mail, id) }
                                invalidMail={invalidMail}
                                setTypeLogin={setTypeLogin}
                            ></Email>:null
                        }
                        { (userId !== null && userEmail !== null && typeLogin.code)?
                            <Code 
                                id={userId} 
                                email={userEmail}
                                nickname={userNickname}
                                setUser={ (id) => setUser(id) }
                                showModal={ ()=> setShowModal(true)}
                            ></Code>:null
                        }                                                
                        { (userId === null && userEmail === null && typeLogin.register)?
                            <Register 
                                onContinue={ (mail, id, nickname) => { 
                                    setEmail(mail, id)
                                    setNickname(nickname)
                                }} 
                                onBadMail={ (mail)=>setBadMail(mail) }
                                invalidMail={invalidMail}
                                setTypeLogin={setTypeLogin}
                            ></Register>:null
                        }                                      
                    </div>
                </div>
            </div>
        </div>
    </main> 
    </>
}
const mapStateToprops = (state)=>{        
    return {...state}  
}
export default connect(mapStateToprops,{setUser:setUser})(Login)