import React, { useEffect, useState, useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import {Context} from "../lang/Wrapper"
import { setUser } from '../../actions'
import ModalSedes from '../Login/ModalSedes'
import {UserAPI} from '../Api/UserAPI'


const Legal = props => {
  const context = useContext(Context);
  const [showSedes, setShowSedes] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    props.setPage('privacy-policy');
    UserAPI.get().then((data)=>{
      if (data !== null){        
        props.setUser({id: data});
        context.selectLanguage(data.idioma);
      }
    });
  }, []); 

  return <main className="cookies">
          {showSedes?<ModalSedes close={()=>setShowSedes(false)} buttonText="Cerrar"/>:null}
          <div className="wrapper-cookies">
            <h2><FormattedMessage id = "app.legal.title"/></h2>
          </div>
          <div className="info">  
              <FormattedMessage id = "app.legal.text" 
                values={{
                  b: (chunks) => <strong>{chunks}</strong>,
                  link: (chunks) => <a href={chunks}>{chunks}</a>,
                  p: (chunks) => <p>{chunks}</p>,
                  br: <br />
                }}/>
                 <FormattedMessage id = "app.legal.responsible.title" 
                values={{
                  b: (chunks) => <strong>{chunks}</strong>,
                  link: (chunks) => <a href={chunks}>{chunks}</a>,
                  p: (chunks) => <p>{chunks}</p>,
                  br: <br />
                }}/>
                 <FormattedMessage id = "app.legal.responsible.text" 
                values={{
                  b: (chunks) => <strong>{chunks}</strong>,
                  link: (chunks) => <a href={chunks}>{chunks}</a>,
                  p: (chunks) => <p>{chunks}</p>,
                  br: <br />
                }}/>
                  <FormattedMessage id = "app.legal.datatype.title" 
                    values={{
                      b: (chunks) => <strong>{chunks}</strong>,
                      link: (chunks) => <a href={chunks}>{chunks}</a>,
                      p: (chunks) => <p>{chunks}</p>,
                      br: <br />
                    }}/>
                  <FormattedMessage id = "app.legal.datatype.text" 
                values={{
                  b: (chunks) => <strong>{chunks}</strong>,
                  link: (chunks) => <a href={chunks}>{chunks}</a>,
                  p: (chunks) => <p>{chunks}</p>,
                  br: <br />
                }}/>
                <ul className='list-roman'>
                  <li>
                    <FormattedMessage id = "app.legal.datatype.list1" 
                      values={{
                        b: (chunks) => <strong>{chunks}</strong>,
                        link: (chunks) => <a href={chunks}>{chunks}</a>,
                        p: (chunks) => <p>{chunks}</p>,
                        br: <br />
                      }}/>
                  </li>
                  <li>
                    <FormattedMessage id = "app.legal.datatype.list2" 
                      values={{
                        b: (chunks) => <strong>{chunks}</strong>,
                        link: (chunks) => <a href={chunks}>{chunks}</a>,
                        p: (chunks) => <p>{chunks}</p>,
                        br: <br />
                      }}/>
                  </li>
                  <li>
                    <FormattedMessage id = "app.legal.datatype.list3" 
                      values={{
                        b: (chunks) => <strong>{chunks}</strong>,
                        link: (chunks) => <a href={chunks}>{chunks}</a>,
                        p: (chunks) => <p>{chunks}</p>,
                        br: <br />
                      }}/>
                  </li>
                </ul>
                <FormattedMessage id = "app.legal.datatype.text2" 
                values={{
                  b: (chunks) => <strong>{chunks}</strong>,
                  link: (chunks) => <a href={chunks}>{chunks}</a>,
                  p: (chunks) => <p>{chunks}</p>,
                  br: <br />
                }}/>
               <FormattedMessage id = "app.legal.datatype.text3" 
                values={{
                  b: (chunks) => <strong>{chunks}</strong>,
                  link: (chunks) => <a href={chunks}>{chunks}</a>,
                  p: (chunks) => <p>{chunks}</p>,
                  br: <br />
                }}/>
              <FormattedMessage id = "app.legal.personal.title" 
                values={{
                  b: (chunks) => <strong>{chunks}</strong>,
                  link: (chunks) => <a href={chunks}>{chunks}</a>,
                  p: (chunks) => <p>{chunks}</p>,
                  br: <br />
                }}/>
                <FormattedMessage id = "app.legal.personal.text" 
                values={{
                  b: (chunks) => <strong>{chunks}</strong>,
                  link: (chunks) => <a href={chunks}>{chunks}</a>,
                  p: (chunks) => <p>{chunks}</p>,
                  br: <br />
                }}/>
                <ul className='list-roman'>
                  <li>
                      <FormattedMessage id = "app.legal.personal.list1" 
                        values={{
                          b: (chunks) => <strong>{chunks}</strong>,
                          link: (chunks) => <a href={chunks}>{chunks}</a>,
                          p: (chunks) => <p>{chunks}</p>,
                          br: <br />
                        }}/>
                  </li>
                  <li>
                      <FormattedMessage id = "app.legal.personal.list2" 
                        values={{
                          b: (chunks) => <strong>{chunks}</strong>,
                          link: (chunks) => <a href={chunks}>{chunks}</a>,
                          p: (chunks) => <p>{chunks}</p>,
                          br: <br />
                        }}/>
                  </li>
                  <li>
                      <FormattedMessage id = "app.legal.personal.list3" 
                        values={{
                          b: (chunks) => <strong>{chunks}</strong>,
                          link: (chunks) => <a href={chunks}>{chunks}</a>,
                          p: (chunks) => <p>{chunks}</p>,
                          br: <br />
                        }}/>
                  </li>
                  <li>
                      <FormattedMessage id = "app.legal.personal.list4" 
                        values={{
                          b: (chunks) => <strong>{chunks}</strong>,
                          link: (chunks) => <a href={chunks}>{chunks}</a>,
                          p: (chunks) => <p>{chunks}</p>,
                          br: <br />
                        }}/>
                  </li>
                  <li>
                      <FormattedMessage id = "app.legal.personal.list5" 
                        values={{
                          b: (chunks) => <strong>{chunks}</strong>,
                          link: (chunks) => <a href={chunks}>{chunks}</a>,
                          p: (chunks) => <p>{chunks}</p>,
                          br: <br />
                        }}/>
                  </li>
                  <li>
                      <FormattedMessage id = "app.legal.personal.list6" 
                        values={{
                          b: (chunks) => <strong>{chunks}</strong>,
                          link: (chunks) => <a onClick={(event)=>{setShowSedes(true); event.preventDefault();}} target="_blank" rel="noreferrer" href={chunks}>{chunks}</a>,                          
                          p: (chunks) => <p>{chunks}</p>,
                          br: <br />
                        }}/>
                  </li>
                  <li>
                      <FormattedMessage id = "app.legal.personal.list7" 
                        values={{
                          b: (chunks) => <strong>{chunks}</strong>,
                          link: (chunks) => <a onClick={(event)=>{setShowSedes(true); event.preventDefault();}} target="_blank" rel="noreferrer" href={chunks}>{chunks}</a>,                          
                          p: (chunks) => <p>{chunks}</p>,
                          br: <br />
                        }}/>
                  </li>
                  <li>
                      <FormattedMessage id = "app.legal.personal.list8" 
                        values={{
                          b: (chunks) => <strong>{chunks}</strong>,
                          link: (chunks) => <a onClick={(event)=>{setShowSedes(true); event.preventDefault();}} target="_blank" rel="noreferrer" href={chunks}>{chunks}</a>,                          
                          p: (chunks) => <p>{chunks}</p>,
                          br: <br />
                        }}/>
                  </li>
                  <li>
                      <FormattedMessage id = "app.legal.personal.list9" 
                        values={{
                          b: (chunks) => <strong>{chunks}</strong>,
                          link: (chunks) => <a onClick={(event)=>{setShowSedes(true); event.preventDefault();}} target="_blank" rel="noreferrer" href={chunks}>{chunks}</a>,                          
                          p: (chunks) => <p>{chunks}</p>,
                          br: <br />
                        }}/>
                  </li>
                  <li>
                      <FormattedMessage id = "app.legal.personal.list10" 
                        values={{
                          b: (chunks) => <strong>{chunks}</strong>,
                          link: (chunks) => <a onClick={(event)=>{setShowSedes(true); event.preventDefault();}} target="_blank" rel="noreferrer" href={chunks}>{chunks}</a>,                          
                          p: (chunks) => <p>{chunks}</p>,
                          br: <br />
                        }}/>
                  </li>
                </ul>
                <FormattedMessage id = "app.legal.providedetails.title" 
                values={{
                  b: (chunks) => <strong>{chunks}</strong>,
                  link: (chunks) => <a href={chunks}>{chunks}</a>,
                  p: (chunks) => <p>{chunks}</p>,
                  br: <br />
                }}/>
                 <FormattedMessage id = "app.legal.providedetails.text" 
                values={{
                  b: (chunks) => <strong>{chunks}</strong>,
                  link: (chunks) => <a href={chunks}>{chunks}</a>,
                  p: (chunks) => <p>{chunks}</p>,
                  br: <br />
                }}/>

                <FormattedMessage id = "app.legal.keepdata.title" 
                values={{
                  b: (chunks) => <strong>{chunks}</strong>,
                  link: (chunks) => <a href={chunks}>{chunks}</a>,
                  p: (chunks) => <p>{chunks}</p>,
                  br: <br />
                }}/>
                 <FormattedMessage id = "app.legal.keepdata.text" 
                values={{
                  b: (chunks) => <strong>{chunks}</strong>,
                  link: (chunks) => <a href={chunks}>{chunks}</a>,
                  p: (chunks) => <p>{chunks}</p>,
                  br: <br />
                }}/>
                <ul className='list-ball'>
                  <li>
                      <FormattedMessage id = "app.legal.keepdata.list1" 
                    values={{
                      b: (chunks) => <strong>{chunks}</strong>,
                      link: (chunks) => <a href={chunks}>{chunks}</a>,
                      p: (chunks) => <p>{chunks}</p>,
                      br: <br />
                    }}/>
                  </li>
                  <li>
                    <FormattedMessage id = "app.legal.keepdata.list2" 
                      values={{
                        b: (chunks) => <strong>{chunks}</strong>,
                        link: (chunks) => <a href={chunks}>{chunks}</a>,
                        p: (chunks) => <p>{chunks}</p>,
                        br: <br />
                      }}/>
                  </li>
                  <li>
                    <FormattedMessage id = "app.legal.keepdata.list3" 
                      values={{
                        b: (chunks) => <strong>{chunks}</strong>,
                        link: (chunks) => <a href={chunks}>{chunks}</a>,
                        p: (chunks) => <p>{chunks}</p>,
                        br: <br />
                      }}/>
                  </li>
                </ul>
                <FormattedMessage id = "app.legal.keepdata.text2" 
                values={{
                  b: (chunks) => <strong>{chunks}</strong>,
                  link: (chunks) => <a href={chunks}>{chunks}</a>,
                  p: (chunks) => <p>{chunks}</p>,
                  br: <br />
                }}/>
                 <FormattedMessage id = "app.legal.share.title" 
                values={{
                  b: (chunks) => <strong>{chunks}</strong>,
                  link: (chunks) => <a href={chunks}>{chunks}</a>,
                  p: (chunks) => <p>{chunks}</p>,
                  br: <br />
                }}/>
                 <FormattedMessage id = "app.legal.share.text" 
                values={{
                  b: (chunks) => <strong>{chunks}</strong>,
                  link: (chunks) => <a href={chunks}>{chunks}</a>,
                  p: (chunks) => <p>{chunks}</p>,
                  br: <br />
                }}/>
                <ul className='list-roman'>
                  <li>
                    <FormattedMessage id = "app.legal.share.list1" 
                      values={{
                        b: (chunks) => <strong>{chunks}</strong>,
                        link: (chunks) => <a onClick={(event)=>{setShowSedes(true); event.preventDefault();}} target="_blank" rel="noreferrer" href={chunks}>{chunks}</a>,
                        p: (chunks) => <p>{chunks}</p>,
                        br: <br />
                      }}/>
                  </li>
                  <li>
                    <FormattedMessage id = "app.legal.share.list2" 
                      values={{
                        b: (chunks) => <strong>{chunks}</strong>,
                        link: (chunks) => <a href={chunks}>{chunks}</a>,
                        p: (chunks) => <p>{chunks}</p>,
                        br: <br />
                      }}/>
                      <ul className='list-letter'>
                        <li>
                          <FormattedMessage id = "app.legal.share.list2.list1" 
                          values={{
                            b: (chunks) => <strong>{chunks}</strong>,
                            link: (chunks) => <a href={chunks}>{chunks}</a>,
                            p: (chunks) => <p>{chunks}</p>,
                            br: <br />
                        }}/>
                        </li>
                        <li>
                          <FormattedMessage id = "app.legal.share.list2.list2" 
                          values={{
                            b: (chunks) => <strong>{chunks}</strong>,
                            link: (chunks) => <a href={chunks}>{chunks}</a>,
                            p: (chunks) => <p>{chunks}</p>,
                            br: <br />
                        }}/>
                        </li>
                        <li>
                          <FormattedMessage id = "app.legal.share.list2.list3" 
                          values={{
                            b: (chunks) => <strong>{chunks}</strong>,
                            link: (chunks) => <a href={chunks}>{chunks}</a>,
                            p: (chunks) => <p>{chunks}</p>,
                            br: <br />
                        }}/>
                        </li>
                        <li>
                          <FormattedMessage id = "app.legal.share.list2.list4" 
                          values={{
                            b: (chunks) => <strong>{chunks}</strong>,
                            link: (chunks) => <a href={chunks}>{chunks}</a>,
                            p: (chunks) => <p>{chunks}</p>,
                            br: <br />
                        }}/>
                        </li>
                        <li>
                          <FormattedMessage id = "app.legal.share.list2.list5" 
                          values={{
                            b: (chunks) => <strong>{chunks}</strong>,
                            link: (chunks) => <a href={chunks}>{chunks}</a>,
                            p: (chunks) => <p>{chunks}</p>,
                            br: <br />
                        }}/>
                        </li>
                        <li>
                          <FormattedMessage id = "app.legal.share.list2.list6" 
                          values={{
                            b: (chunks) => <strong>{chunks}</strong>,
                            link: (chunks) => <a href={chunks}>{chunks}</a>,
                            p: (chunks) => <p>{chunks}</p>,
                            br: <br />
                        }}/>
                        </li>
                      </ul>
                  </li>
                  <li>
                    <FormattedMessage id = "app.legal.share.list3" 
                      values={{
                        b: (chunks) => <strong>{chunks}</strong>,
                        link: (chunks) => <a href={chunks}>{chunks}</a>,
                        p: (chunks) => <p>{chunks}</p>,
                        br: <br />
                      }}/>
                  </li>
                  <li>
                    <FormattedMessage id = "app.legal.share.list4" 
                      values={{
                        b: (chunks) => <strong>{chunks}</strong>,
                        link: (chunks) => <a href={chunks}>{chunks}</a>,
                        p: (chunks) => <p>{chunks}</p>,
                        br: <br />
                      }}/>
                  </li>
                </ul>
                <FormattedMessage id = "app.legal.countries.title" 
                values={{
                  b: (chunks) => <strong>{chunks}</strong>,
                  link: (chunks) => <a href={chunks}>{chunks}</a>,
                  p: (chunks) => <p>{chunks}</p>,
                  br: <br />
                }}/>
                 <FormattedMessage id = "app.legal.countries.text" 
                values={{
                  b: (chunks) => <strong>{chunks}</strong>,
                  link: (chunks) => <a href={chunks}>{chunks}</a>,
                  p: (chunks) => <p>{chunks}</p>,
                  br: <br />
                }}/>
                <ul className='list-roman'>
                  <li>
                  <FormattedMessage id = "app.legal.countries.list1" 
                      values={{
                        b: (chunks) => <strong>{chunks}</strong>,
                        link: (chunks) => <a href={chunks}>{chunks}</a>,
                        p: (chunks) => <p>{chunks}</p>,
                        br: <br />
                      }}/>
                  </li>
                  <li>
                  <FormattedMessage id = "app.legal.countries.list2" 
                      values={{
                        b: (chunks) => <strong>{chunks}</strong>,
                        link: (chunks) => <a href={chunks}>{chunks}</a>,
                        p: (chunks) => <p>{chunks}</p>,
                        br: <br />
                      }}/>
                  </li>
                  <li>
                  <FormattedMessage id = "app.legal.countries.list3" 
                      values={{
                        b: (chunks) => <strong>{chunks}</strong>,
                        link: (chunks) => <a href={chunks}>{chunks}</a>,
                        p: (chunks) => <p>{chunks}</p>,
                        br: <br />
                      }}/>
                  </li>
                </ul>
                <FormattedMessage id = "app.legal.countries.text2" 
                values={{
                  b: (chunks) => <strong>{chunks}</strong>,
                  link: (chunks) => <a href={chunks}>{chunks}</a>,
                  p: (chunks) => <p>{chunks}</p>,
                  br: <br />
                }}/>
                  <FormattedMessage id = "app.legal.protect.title" 
                values={{
                  b: (chunks) => <strong>{chunks}</strong>,
                  link: (chunks) => <a href={chunks}>{chunks}</a>,
                  p: (chunks) => <p>{chunks}</p>,
                  br: <br />
                }}/>
                   <FormattedMessage id = "app.legal.protect.text" 
                values={{
                  b: (chunks) => <strong>{chunks}</strong>,
                  link: (chunks) => <a href={chunks}>{chunks}</a>,
                  p: (chunks) => <p>{chunks}</p>,
                  br: <br />
                }}/>
                      <FormattedMessage id = "app.legal.rights.title" 
                values={{
                  b: (chunks) => <strong>{chunks}</strong>,
                  link: (chunks) => <a href={chunks}>{chunks}</a>,
                  p: (chunks) => <p>{chunks}</p>,
                  br: <br />
                }}/>
                   <FormattedMessage id = "app.legal.rights.text1" 
                values={{
                  b: (chunks) => <strong>{chunks}</strong>,
                  link: (chunks) => <a href={chunks}>{chunks}</a>,
                  p: (chunks) => <p>{chunks}</p>,
                  br: <br />
                }}/>
                  <FormattedMessage id = "app.legal.rights.text2" 
                values={{
                  b: (chunks) => <strong>{chunks}</strong>,
                  link: (chunks) => <a href={chunks}>{chunks}</a>,
                  p: (chunks) => <p>{chunks}</p>,
                  u: (chunks) => <u>{chunks}</u>,
                  br: <br />
                }}/>
                  <FormattedMessage id = "app.legal.rights.text3" 
                values={{
                  b: (chunks) => <strong>{chunks}</strong>,
                  link: (chunks) => <a href={chunks}>{chunks}</a>,
                  p: (chunks) => <p>{chunks}</p>,
                  u: (chunks) => <u>{chunks}</u>,
                  br: <br />
                }}/>
                  <FormattedMessage id = "app.legal.rights.text4" 
                values={{
                  b: (chunks) => <strong>{chunks}</strong>,
                  link: (chunks) => <a href={chunks}>{chunks}</a>,
                  p: (chunks) => <p>{chunks}</p>,
                  u: (chunks) => <u>{chunks}</u>,
                  br: <br />
                }}/>
                  <FormattedMessage id = "app.legal.rights.text5" 
                values={{
                  b: (chunks) => <strong>{chunks}</strong>,
                  link: (chunks) => <a href={chunks}>{chunks}</a>,
                  p: (chunks) => <p>{chunks}</p>,
                  u: (chunks) => <u>{chunks}</u>,
                  br: <br />
                }}/>
         
          </div>
        </main>
}
const mapStateToprops = (state)=>{        
  return {...state};  
}
export default connect(mapStateToprops,{setUser:setUser})(Legal)