import React from 'react'
//import { useNavigate } from 'react-router-dom'
import {motion} from "framer-motion"
//import {FormattedMessage } from 'react-intl';
//import Success from '../../assets/images/success.png'
import useWindowSize from '../hooks/useWindowSize'

const ModalF1 = (props) => {
  
  const size = useWindowSize();
  const tablet = 768;
  const y = size.width > tablet?"-50%":"0";
  const x = size.width > tablet?"-50%":"0";

  return <div className="modal modalOK modalF1">
    <motion.div animate={{ opacity:1, x: x, y: y, transition: { duration: 0.5, type: "spring", bounce: 0.5 }}} initial={{opacity:0, x: -200, y: y}} exit={{opacity:0, x:-600, transition: { duration: 0.2 } }} layout className="modalWrapper">
      <div className="modalContent">
            <div className="right">
                <h2>
                  {props.text}              
                </h2>
                <button onClick={()=>{props.close()}} className="btn"> 
                  {props.buttonText}
                </button>
            </div>
      </div>     
    </motion.div>
  </div>
}

export default ModalF1