import React from 'react'
import { useIntl } from 'react-intl'

const HowRankingWork = () => {
    const intl = useIntl() 

    return  <>
                <p>{intl.formatMessage({ id: "app.faq.howrankingwork.text1" })}</p>
                <ul className="listHowRankingWork">
                    <li>{intl.formatMessage({ id: "app.faq.howrankingwork.one" })}</li>
                    <li>{intl.formatMessage({ id: "app.faq.howrankingwork.two" })}</li>
                </ul>
                <p>{intl.formatMessage({ id: "app.faq.howrankingwork.text2" })}</p>
            </>
}
export default HowRankingWork
