import React, { useEffect } from 'react'
import {/* useIntl,*/ FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'


const Cookies = props => {
 // const intl = useIntl()

  useEffect(() => {
    window.scrollTo(0, 0);
    props.setPage('cookies');
  }, []); 

  return <main className="cookies">
        <div className="wrapper-cookies">
          <h2><FormattedMessage id = "app.cookies.title"/></h2>
        </div>
        <ul className="info">
          <li><FormattedMessage id = "app.cookies.section1.title"/>
            <p><FormattedMessage id = "app.cookies.section1.text" 
                values={{
                  b: (chunks) => <strong>{chunks}</strong>,
                  link: (chunks) => <a href={chunks}>{chunks}</a>,
                  br: <br />
                }}/>
            </p>
          </li>
          <li><FormattedMessage id = "app.cookies.section2.title"/>
            <FormattedMessage id = "app.cookies.section2.text" 
                values={{
                  b: (chunks) => <strong>{chunks}</strong>,
                  link: (chunks) => <a href={chunks}>{chunks}</a>,
                  p: (chunks) => <p>{chunks}</p>,
                  br: <br />
                }}/>
          </li>
          <li><FormattedMessage id = "app.cookies.section3.title"/>
            <FormattedMessage id = "app.cookies.section3.text" 
                values={{
                  b: (chunks) => <strong>{chunks}</strong>,
                  link: (chunks) => <a href={chunks}>{chunks}</a>,
                  p: (chunks) => <p>{chunks}</p>,
                  a: (chunks) => <a href="https://www.santanderlapzero.com/?page=privacy-policy">{chunks}</a>,
                  br: <br />
                }}/>
          </li>
          <li><FormattedMessage id = "app.cookies.section4.title"/>
            <FormattedMessage id = "app.cookies.section4.text" 
                values={{
                  b: (chunks) => <strong>{chunks}</strong>,
                  link: (chunks) => <a href={chunks}>{chunks}</a>,
                  p: (chunks) => <p>{chunks}</p>,
                  br: <br />
                }}/>
                <div className='subsection'>
                  <FormattedMessage id = "app.cookies.section4.subtitle1"
                    values={{
                      b: (chunks) => <strong>{chunks}</strong>,
                      link: (chunks) => <a href={chunks}>{chunks}</a>,
                      p: (chunks) => <p>{chunks}</p>,
                      br: <br />
                    }}/>
                    <FormattedMessage id = "app.cookies.section4.subtext1"
                    values={{
                      b: (chunks) => <strong>{chunks}</strong>,
                      link: (chunks) => <a href={chunks}>{chunks}</a>,
                      p: (chunks) => <p>{chunks}</p>,
                      br: <br />
                    }}/>
                    <div className="table-wrapper">
                    <table>
                      <thead>
                        <tr>
                          <th><FormattedMessage id = "app.cookies.section4.tablehead1"/></th>
                          <th><FormattedMessage id = "app.cookies.section4.tablehead2"/></th>
                          <th><FormattedMessage id = "app.cookies.section4.tablehead3"/></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><FormattedMessage id = "app.cookies.section4.tablerow1"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.tablerow2"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.tablerow3"/><FormattedMessage id = "app.cookies.days"/></td>
                        </tr>
                        <tr>
                          <td><FormattedMessage id = "app.cookies.section4.tablerow4"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.tablerow5"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.tablerow6"/><FormattedMessage id = "app.cookies.days"/></td>
                        </tr>
                        <tr>
                          <td><FormattedMessage id = "app.cookies.section4.tablerow7"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.tablerow8"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.tablerow9"/></td>
                        </tr>
                        <tr>
                          <td><FormattedMessage id = "app.cookies.section4.tablerow10"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.tablerow11"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.tablerow12"/></td>
                        </tr>
                        <tr>
                          <td><FormattedMessage id = "app.cookies.section4.tablerow13"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.tablerow14"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.tablerow15"/><FormattedMessage id = "app.cookies.days"/></td>
                        </tr>
                      </tbody>
                    </table>
                    </div>
                    <FormattedMessage id = "app.cookies.section4.subtitle2"
                    values={{
                      b: (chunks) => <strong>{chunks}</strong>,
                      link: (chunks) => <a href={chunks}>{chunks}</a>,
                      p: (chunks) => <p>{chunks}</p>,
                      br: <br />
                    }}/>
                    <FormattedMessage id = "app.cookies.section4.subtext2"
                    values={{
                      b: (chunks) => <strong>{chunks}</strong>,
                      link: (chunks) => <a href={chunks}>{chunks}</a>,
                      p: (chunks) => <p>{chunks}</p>,
                      br: <br />
                    }}/>
                    <div className="table-wrapper">
                    <table>
                      <thead>
                        <tr>
                          <th><FormattedMessage id = "app.cookies.section4.subsection2.tablehead1"/></th>
                          <th><FormattedMessage id = "app.cookies.section4.subsection2.tablehead2"/></th>
                          <th><FormattedMessage id = "app.cookies.section4.subsection2.tablehead3"/></th>
                          <th><FormattedMessage id = "app.cookies.section4.subsection2.tablehead4"/></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><FormattedMessage id = "app.cookies.section4.subsection2.tablerow1.1"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection2.tablerow1.2"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection2.tablerow1.3"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection2.tablerow1.4"/></td>
                        </tr>
                        <tr>
                          <td><FormattedMessage id = "app.cookies.section4.subsection2.tablerow2.1"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection2.tablerow2.2"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection2.tablerow2.3"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection2.tablerow2.4"/></td>
                        </tr>
                        <tr>
                          <td><FormattedMessage id = "app.cookies.section4.subsection2.tablerow4.1"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection2.tablerow4.2"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection2.tablerow4.3"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection2.tablerow4.4"/></td>
                        </tr>
                      </tbody>
                    </table>
                    </div>
                    <FormattedMessage id = "app.cookies.section4.subtitle3"
                    values={{
                      b: (chunks) => <strong>{chunks}</strong>,
                      link: (chunks) => <a href={chunks}>{chunks}</a>,
                      p: (chunks) => <p>{chunks}</p>,
                      br: <br />
                    }}/>
                    <FormattedMessage id = "app.cookies.section4.subtext3"
                    values={{
                      b: (chunks) => <strong>{chunks}</strong>,
                      link: (chunks) => <a href={chunks}>{chunks}</a>,
                      p: (chunks) => <p>{chunks}</p>,
                      br: <br />
                    }}/>
                    <div className="table-wrapper">
                    <table>
                      <thead>
                        <tr>
                          <th><FormattedMessage id = "app.cookies.section4.subsection2.tablehead1"/></th>
                          <th><FormattedMessage id = "app.cookies.section4.subsection2.tablehead2"/></th>
                          <th><FormattedMessage id = "app.cookies.section4.subsection2.tablehead3"/></th>
                          <th><FormattedMessage id = "app.cookies.section4.subsection2.tablehead4"/></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow1.1"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow1.2"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow1.3"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow1.4"/></td>
                        </tr>
                        <tr>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow2.1"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow2.2"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow2.3"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow2.4"/></td>
                        </tr>
                        <tr>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow3.1"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow3.2"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow3.3"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow3.4"/></td>
                        </tr>
                        <tr>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow4.1"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow4.2"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow4.3"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow4.4"/></td>
                        </tr>
                        <tr>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow5.1"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow5.2"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow5.3"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow5.4"/></td>
                        </tr>
                        <tr>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow6.1"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow6.2"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow6.3"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow6.4"/></td>
                        </tr>
                        <tr>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow7.1"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow7.2"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow7.3"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow7.4"/></td>
                        </tr>
                        <tr>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow8.1"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow8.2"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow8.3"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow8.4"/></td>
                        </tr>
                        <tr>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow9.1"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow9.2"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow9.3"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow9.4"/></td>
                        </tr>
                        <tr>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow10.1"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow10.2"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow10.3"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow10.4"/></td>
                        </tr>
                        <tr>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow11.1"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow11.2"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow11.3"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow11.4"/></td>
                        </tr>
                        <tr>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow12.1"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow12.2"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow12.3"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow12.4"/></td>
                        </tr>
                        <tr>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow13.1"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow13.2"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow13.3"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow13.4"/></td>
                        </tr>
                        <tr>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow14.1"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow14.2"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow14.3"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow14.4"/></td>
                        </tr>
                        <tr>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow15.1"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow15.2"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow15.3"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow15.4"/></td>
                        </tr>
                        <tr>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow16.1"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow16.2"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow16.3"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow16.4"/></td>
                        </tr>
                        <tr>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow17.1"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow17.2"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow17.3"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow17.4"/></td>
                        </tr>
                        <tr>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow18.1"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow18.2"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow18.3"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow18.4"/></td>
                        </tr>
                        <tr>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow19.1"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow19.2"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow19.3"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow19.4"/></td>
                        </tr>
                        <tr>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow20.1"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow20.2"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow20.3"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow20.4"/></td>
                        </tr>
                        <tr>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow21.1"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow21.2"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow21.3"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow21.4"/></td>
                        </tr>
                        <tr>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow22.1"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow22.2"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow22.3"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow22.4"/></td>
                        </tr>
                        <tr>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow23.1"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow23.2"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow23.3"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow23.4"/></td>
                        </tr>
                        <tr>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow24.1"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow24.2"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow24.3"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow24.4"/></td>
                        </tr>
                        <tr>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow25.1"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow25.2"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow25.3"/></td>
                          <td><FormattedMessage id = "app.cookies.section4.subsection3.tablerow25.4"/></td>
                        </tr>
                      </tbody>
                    </table>
                    </div>
                </div>                
          </li>
          <li><FormattedMessage id = "app.cookies.section5.title"/>
            <FormattedMessage id = "app.cookies.section5.text" 
                values={{
                  b: (chunks) => <strong>{chunks}</strong>,
                  link: (chunks) => <a href={chunks}>{chunks}</a>,
                  p: (chunks) => <p>{chunks}</p>,
                  a: (chunks) => <a href="https://www.santanderlapzero.com/?page=privacy-policy">{chunks}</a>,
                  br: <br />
                }}/>
          </li>
          <li><FormattedMessage id = "app.cookies.section6.title"/>
            <FormattedMessage id = "app.cookies.section6.text" 
                values={{
                  b: (chunks) => <strong>{chunks}</strong>,
                  link: (chunks) => <a href={chunks}>{chunks}</a>,
                  p: (chunks) => <p>{chunks}</p>,
                  a: (chunks) => <a href="https://www.santanderlapzero.com/?page=privacy-policy">{chunks}</a>,
                  br: <br />
                }}/>
                <ul className='list-ball'>
                  <li><FormattedMessage id = "app.cookies.section6.li.title1" 
                      values={{
                        b: (chunks) => <strong>{chunks}</strong>,
                        link: (chunks) => <a href={chunks}>{chunks}</a>,
                        underline: (chunks) => <u>{chunks}</u>,
                        p: (chunks) => <p>{chunks}</p>,
                        br: <br />
                      }}/>
                      <ul>
                        <li>
                            <FormattedMessage id = "app.cookies.section6.subli1" 
                            values={{
                              b: (chunks) => <strong>{chunks}</strong>,
                              link: (chunks) => <a href={chunks}>{chunks}</a>,
                              underline: (chunks) => <u>{chunks}</u>,
                              p: (chunks) => <p>{chunks}</p>,
                              br: <br />
                            }}/>
                        </li>
                        <li>
                            <FormattedMessage id = "app.cookies.section6.subli2" 
                            values={{
                              b: (chunks) => <strong>{chunks}</strong>,
                              link: (chunks) => <a href={chunks}>{chunks}</a>,
                              underline: (chunks) => <u>{chunks}</u>,
                              p: (chunks) => <p>{chunks}</p>,
                              br: <br />
                            }}/>
                        </li>
                        <li>
                            <FormattedMessage id = "app.cookies.section6.subli3" 
                            values={{
                              b: (chunks) => <strong>{chunks}</strong>,
                              link: (chunks) => <a href={chunks}>{chunks}</a>,
                              underline: (chunks) => <u>{chunks}</u>,
                              p: (chunks) => <p>{chunks}</p>,
                              br: <br />
                            }}/>
                        </li>
                        <li>
                            <FormattedMessage id = "app.cookies.section6.subli4" 
                            values={{
                              b: (chunks) => <strong>{chunks}</strong>,
                              link: (chunks) => <a href={chunks}>{chunks}</a>,
                              underline: (chunks) => <u>{chunks}</u>,
                              p: (chunks) => <p>{chunks}</p>,
                              br: <br />
                            }}/>
                        </li>
                        <li>
                            <FormattedMessage id = "app.cookies.section6.subli5" 
                            values={{
                              b: (chunks) => <strong>{chunks}</strong>,
                              link: (chunks) => <a href={chunks}>{chunks}</a>,
                              underline: (chunks) => <u>{chunks}</u>,
                              p: (chunks) => <p>{chunks}</p>,
                              br: <br />
                            }}/>
                        </li>
                      </ul>
                  </li>
                  <li><FormattedMessage id = "app.cookies.section6.li.title2" 
                      values={{
                        b: (chunks) => <strong>{chunks}</strong>,
                        link: (chunks) => <a href={chunks}>{chunks}</a>,
                        underline: (chunks) => <u>{chunks}</u>,
                        p: (chunks) => <p>{chunks}</p>,
                        br: <br />
                      }}/>
                  </li>
                </ul>
          </li>
          <li><FormattedMessage id = "app.cookies.section7.title"/>
            <FormattedMessage id = "app.cookies.section7.text" 
                values={{
                  b: (chunks) => <strong>{chunks}</strong>,
                  link: (chunks) => <a href={chunks}>{chunks}</a>,
                  p: (chunks) => <p>{chunks}</p>,
                  br: <br />
                }}/>
          </li>
        </ul>
  </main>
}
const mapStateToprops = (state)=>{        
  return {...state};  
}
export default connect(mapStateToprops)(Cookies)