import React, {  useState} from 'react'
import {useIntl} from 'react-intl'
import axios from 'axios'
import ModalCustom from './ModalCustom'
import Button from './Button'
import { AnimatePresence } from 'framer-motion'
import { connect } from 'react-redux'

const ShareButton = (props) => {
    const [url, setUrl] = useState(null)
    const [modal, setModal] = useState(false)
    const intl = useIntl()    
      
    const instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,		
        timeout: 16000,
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('JWT') }
    });

    const shareProcess = () => {
       
        const lang = props.user.id.idioma.toUpperCase()=="EN"?"en/":"";//intl.locale.toUpperCase()==="EN"?"en/":"";
        //console.log("locale", props.user.id.idioma, intl.locale.toUpperCase());
        if(props.active===0) 
            instance.get("api/quiz/share/"+props.gp).then( (response)=>{
                //console.log(response.data);	
                const url = (process.env.REACT_APP_SHARE_URL + lang + "gp/ranking/" + response.data.data.url).replace("'","");
                setUrl(url)
                clickShare(url)
            
            }).catch( (error)=>{
                console.log(error);
                /*if (error.response.data.error === "quiz unfinished"){
                    console.log("Qiz unfinished");
                    showModalQizNotFinished
                }*/
            })
        if(props.active===1){
            const url = (process.env.REACT_APP_SHARE_URL + lang + "ranking/" + props.user.id.id).replace("'","");
            setUrl(url)
            clickShare(url)                
        }
        if(props.active===2){
            instance.get("api/team/details").then( (response)=>{
                //console.log(response.data);	
                const codeTeam = response.data.data.code
                const url = (process.env.REACT_APP_SHARE_URL + lang + "team/ranking/" + codeTeam).replace("'","");
                    
                setUrl(url)
                props.setUrl(url)
                clickShare(url) 
                           
            }).catch( (error)=>{
                console.log(error);
            })
        }
	}

    const clickShare = (url) => {

        if (navigator.share) {
			navigator.share({
				title: 'Santander Formula 1',
				text: intl.formatMessage({ id: 'app.quiz.share' }),//'Únete a mi equipo Santander Formula 1',
				url: url,
			})
			.then(() => {
                //console.log('Successful share')
            })
			.catch((error) => console.log('Error sharing', error));
        }else{
            //modal para copiar url
            setModal(true)
            if(props.modalOutside){
                props.setUrl(url)
                props.setModal(true)
            }
        }
    }

    const copyLink = () => {
        navigator.clipboard.writeText(url)
        setModal(false)
    }    

    /*const  makeid = (length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
          counter += 1;
        }
        return result;
    }

    const getId = () =>{
        //console.log("geId", props.idButton, (typeof props.idButton === 'undefined')?makeid(6)+props.color:props.idButton);
        return (typeof props.idButton === 'undefined')?makeid(6)+props.color:props.idButton;
    }*/

    return  <>
                {!props.modalOutside ?
                <AnimatePresence>
                    { modal ?
                        <ModalCustom title={intl.formatMessage({ id: 'app.quiz.share.modaltitle' })} text={url}
                        onClose={copyLink} btnTextContinue={intl.formatMessage({ id: 'app.quiz.share.copy' })} onContinue={copyLink} />
                    : null}
                </AnimatePresence>
                :null
                }
                <Button
                  idButton={props.idButton}
                  color={props.color}
                  icon={props.icon}
                  clicked={() => {
                    shareProcess()
                  }}
                  text={props.text}
                />
            </>

}
//export default ShareClick
const mapStateToprops = (state)=>{        
    return {...state};  
}
export default connect(mapStateToprops)(ShareButton)