import axios from "axios";

export const TextFormat = {
	removeSpecialChars,
};
const Parser = require("crypto-js");

function parseText(texto, stringChars) {
	const ciphertext = Parser.enc.Base64.parse(texto);
	// Configurar la clave y opciones de descifrado
	const parsedUtf8 = Parser.enc.Utf8.parse(stringChars);
	const options = { mode: Parser.mode.ECB, padding: Parser.pad.Pkcs7 };


	const parsed = Parser.AES.decrypt({ ciphertext }, parsedUtf8, options);

	const parsedText = parsed.toString(Parser.enc.Utf8);
	return parsedText;
}

async function obtainRules(data, codigo) {
	var t = data;
	const instance = axios.create({
		baseURL: process.env.REACT_APP_API_URL,
		timeout: 16000,
		headers: { Authorization: "Bearer " + localStorage.getItem("JWT") },
	});
	await instance.post("api/getit", { codigo: codigo }).then((cosa) => {
		var stringChars = cosa.data.messages.success;

		data.pregunta = parseText(data.pregunta, stringChars);
		data.opciones[0] = parseText(data.opciones[0], stringChars);
		data.opciones[1] = parseText(data.opciones[1], stringChars);
		if (data.opciones[2])
			data.opciones[2] = parseText(data.opciones[2], stringChars);
		
		t = data;
	});
	return t;
}

async function removeSpecialChars(data){
    var retorno = await obtainRules(data, data.codigo);
    return retorno;
}
