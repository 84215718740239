import React from 'react'
import iconClose from '../../assets/icons/close.svg'

const ModalVideo = ({ video, close}) => {
  const [progress, setProgress] = React.useState(0);

  const handleProgress = (e) => {
    if (isNaN(e.target.duration))   // duration is NotaNumber at Beginning.
      return;
    setProgress(Math.ceil((e.target.currentTime / e.target.duration) * 100));
    //console.log("progress", progress);
  }

  return <div className="modal modalF1 modalVideo">
    <div className="modalWrapper">
      <div className="modalHeader">
        <button className="btnIcon btnClose" onClick={() => close()}>
          <img src={iconClose} alt="close" />
        </button>
      </div>

      <div className="modalContent">
        <video src={video} autoPlay onTimeUpdate={handleProgress} />
        <div className="progress-bar" style={{}}>
          <div className="progress" style={{ width: progress+"%"}}/>
        </div>
      </div>
    </div>
  </div>
}

export default ModalVideo