import {React, useEffect, useState} from 'react'
//import {Countries} from './Countries'

const Selector = (props) => {
    const [values, setValues] = useState(props.values);
    const others = ["Otros", "Others"];
    
    useEffect(() => {
        setValues(props.values);       
    },[]);

    const selectValue = (value) => {
        props.onSelectValue(value);
    }   
    
    const handleOthers = (event) => {
        //console.log("Others value:", event.target.value);
        selectValue(event.target.value);
    }

return <>    
    {
        (values!== null & props.show)?<>
            <div className="countries">
                {values.map((value, index) => {
                    if (others.includes(value)){
                        return (<div 
                            className={ props.value === value?"country selected":"country" }
                            key={props.key+"-"+index + "-" + value}>
                            <input 
                                type="text" 
                                placeholder={value} 
                                className="formControl" 
                                minLength={2}
                                maxLength={45}
                                onKeyDown={(e)=>{ if(e.key==="Enter"){ handleOthers(e)} }} />
                        </div>)
                    } else {
                    return (<div onClick={ ()=> selectValue(value)} 
                        className={ props.value === value?"country selected":"country" }
                        key={props.key+"-"+index + "-" + value}>                        
                        <span>{value}</span> 
                    </div>)
                    }
                })}
            </div>
        </>:null
    }    
    </>
}
export default Selector