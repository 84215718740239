import React, { useEffect, useContext } from 'react'
import {Context} from "../lang/Wrapper";
import { connect } from 'react-redux'
import { useIntl } from 'react-intl'
import { setUser } from '../../actions'
import axios from 'axios'
import User from '../../assets/images/user.svg'
import Point from '../../assets/images/point.svg'
import Lang from '../../assets/images/language.svg'
import Arrow from '../../assets/images/select-arrow.svg'
import Modal from './ModalDelete'
import ModalCheck from './ModalCheck'
import ModalUpdate from './ModalUpdate'
import ProfileComponent from '../common/Profile'
import Form from './Form'
import {UserAPI} from '../Api/UserAPI'
import { Perfil }  from '../Api/Perfil'

const Profile = props => {
  const context = useContext(Context);
  const intl = useIntl();
  const [showLanguages, setShowLanguages] = React.useState(false);  
  const [showModal, setShowModal] = React.useState(false);  
  const [deleteWasSent, setDeleteWasSent] = React.useState(false);  
  const [showProfile, setShowProfile] = React.useState(false);
  const [perfilIndex, setPerfilIndex] = React.useState(0);
  const [showModalCheck, setShowModalCheck] = React.useState(false);
  const [showModalUpdate, setShowModalUpdate] = React.useState(false);
  const [accept, setAccept] = React.useState(false);
  
  var instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 16000,
    headers: {'Authorization': 'Bearer ' + localStorage.getItem('JWT') }
  });

  useEffect(() => {
    props.setPage('profile');
    Perfil.get().then(data=>{
      if (data !== null){
        setAccept(data.accept==="1");
        //var acceptProfile = data.accept;
        //console.log("accept", data.accept==="1");
      }
    })
  }, []); 
  

  const changeLang = (l) =>{
    setShowLanguages(false);
    //console.log(l);
    //call API and change language
    instance.post('/api/updateLanguage/'+l).then( ()=>{
      var u = props.user.id;
      u.idioma = l;
      props.setUser({ id: u});
      context.selectLanguage(l);            
    }).catch( (error)=>{
        console.log("Error:", error);
    })
  }

  const getCountry = () => {
    var country = 'Germany';
    if ( typeof props.user.id.pais !== 'undefined' && props.user.id.pais.length>0){
      country = props.user.id.pais;
    }
    return country;
  }

  const sendDelete = () => {
    instance.get('/api/hashfreg/'+props.user.id.id.replace('-','').substring(5,10)).then( ()=>{
      setDeleteWasSent(true);
    }).catch( (error)=>{
        console.log("Error:", error);
        setDeleteWasSent(true);
    })
  }

  const updateForm = () => {
    //console.log("updateForm");   
    setShowModalUpdate(true); 
    UserAPI.get().then(u=>{
      setPerfilIndex(perfilIndex+1);
      props.setUser({"id": u});
      setTimeout( ()=>{ setShowModalUpdate(false); }, 300);
      //setShowModalUpdate(false);
    })
  }

  const completeProfile = () =>{
    if (!accept /*localStorage.getItem("Accept_Profile") === null*/){
      setShowModalCheck(true);
    } else {
      setShowProfile(!showProfile);
    }
  }

  const saveCheck = () => {
    Perfil.onePost({key: "accept", valor: 1}).then(data =>{
      //console.log(data);
      if (data !== null){
        setShowModalCheck(false); 
        //localStorage.setItem("Accept_Profile", 1); 
        setAccept(true);
        setShowProfile(!showProfile)
      }
    });
  }

  return <main className="profile">
    <ProfileComponent view="page-view" user={props.user.id} profileCompleted={perfilIndex}/>    
    {showModal?<Modal close={(val)=>setShowModal(val)} send={()=>sendDelete() } sent={deleteWasSent}/>:null}
    {showModalCheck?<ModalCheck close={ ()=>{ setShowModalCheck(false) } } send={ ()=>{ saveCheck(); /*setShowModalCheck(false); localStorage.setItem("Accept_Profile", 1); setShowProfile(!showProfile)*/ }} />:null}
    {showModalUpdate?<ModalUpdate/>:null}
      <div className="wrapper-profile">        
          {/*<h2><span>{ intl.formatMessage({ id:"app.profile.myprofile"})}</span><Casco id={props.user.id.idusuario} nick={ props.user.id.nickname} /></h2>*/}
          <div className="flex">
            
            {/*!showProfile? <Form showOnlyEmpty={true} onUpdate={updateForm}/>:null*/}

            <div className={showProfile?"item nickname show":"item nickname"} onClick={()=>{ completeProfile(); /*setShowProfile(!showProfile)*/ }}>
              <span className="image-wrapper"><img src={User}></img></span>
              {/*<span>{ props.user.id.nickname}</span>*/}
              <span>{ intl.formatMessage({ id:"app.profile.myprofile"})}</span>
              <img src={Arrow}></img> 
            </div>
            {showProfile?<><Form showOnlyEmpty={false} onUpdate={updateForm}/></>:null}            

            <div className="item country">
              <span className="image-wrapper"><img src={Point}></img></span>
              <span>{ intl.formatMessage({ id:"app.profile."+getCountry() })}</span>
            </div>
            
            <div className="item lang">
              <div className="selected-lang"  onClick={ ()=> { setShowLanguages(true) }}>
                <img src={Lang}></img>
                <span>{ props.user.id.idioma==="EN"?<>{ intl.formatMessage({ id:"app.profile.english"})}</>:<>{ intl.formatMessage({ id:"app.profile.spanish"})}</>}</span>
                <img src={Arrow}></img>             
              </div>
              {showLanguages?
                <div className="item langs">
                  <span onClick={()=> changeLang("EN") } className={ props.user.id.idioma === "EN"?"active":"" }>{ intl.formatMessage({ id:"app.profile.english"})}</span>
                  <span onClick={()=> changeLang("ES") } className={ props.user.id.idioma === "ES"?"active":"" }>{ intl.formatMessage({ id:"app.profile.spanish"})}</span>
                  {/*<span onClick={()=> changeLang("PL") } className={ props.user.id.idioma === "PL"?"active":"" }>{ intl.formatMessage({ id:"app.profile.polish"})}</span>*/}
                </div>
                :null
              }
            </div>

            {/*<div className={showProfile?"item nickname show":"item nickname"} onClick={()=>{ setShowUnsubscribe(!showUnsubscribe) }}>
              <span className="image-wrapper"><img src={Lang}></img></span>              
              <span>{ intl.formatMessage({ id:"app.profile.remove"})}</span>
              <img src={Arrow}></img> 
            </div>
            {showUnsubscribe?<><Unsubscribe/></>:null}    
            */}

            <div className="item delete" onClick={ ()=>{ setShowModal(true) }}>
              <span className="image-wrapper"><img src={Arrow} /><img src={Arrow} /></span>
              <span>{ intl.formatMessage({ id:"app.profile.remove"})}</span>
            </div>
          </div>
      </div>
  </main>
}
const mapStateToprops = (state)=>{        
  return {...state};  
}
export default connect(mapStateToprops,{setUser:setUser})(Profile)