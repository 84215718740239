//import { useState } from 'react'

const Level = (props) => {  
    const n = 12;
    const range = [...Array(n).keys()];
    const oneLevel = 100/(n-1);
    //const [open, setOpen] = useState(props.open || true)

  return <div className="level">
    <p>{props.title}</p>
    <div className="bar">
        <div className="full" style={{ width: (oneLevel*props.level)+"%" }}></div>
            <div className="points">
            {
                range.map( (item, index) =>{
                    return <div key={props.title + "-"+index} className={ props.level===index?"active ":"" }></div>
                })
            }             
        </div>
    </div>    
  </div>

}

export default Level