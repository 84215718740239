import { React } from 'react'
import { useIntl } from 'react-intl'

const ModalUpdate = () => {  
    const intl = useIntl();   

  return <div   className="modal modalOK modalF1 modalDelete">
    <div   className="modalWrapper">
      <div className="modalContent" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>            
        <p> { intl.formatMessage({ id:"app.profile.updating", default:"Updating data..."})}</p>
        < div style={{ width: "100px", height:"100px", padding: 0, position: "relative", margin:0 }} className="spinner"/>
      </div>     
    </div>
  </div>
}

export default ModalUpdate