import { useEffect, useState } from 'react'
import { PreguntasEquipos } from '../Api/PreguntasEquipos'
import { RespuestasEquipos } from '../Api/RespuestasEquipos'
import { FormattedMessage } from 'react-intl'
import Step from './Step'
import StepMultiple from './StepMultiple'
//import { convertStringToBoolean } from '../../utils/utils'
import {useNavigate} from  "react-router-dom"

const Test = (props) => {
  var navigate = useNavigate();
  const [step, setStep] = useState(0)
  const [data, setData] = useState([])
  const [dataFilteredByStep, setDataFilteredByStep] = useState([])
  const [isMultiple, setIsMultiple] = useState(false)
  const [isChecked, setIsChecked] = useState('')  
  // get selected value from radios or checkboxes
  const [answer, setAnswer] = useState('')
  const [numSteps, setNumSteps] = useState(10)
  const [loading, setLoading] = useState(true);

  // build object with question and answers
  const buildDataFromApi = num => {
    let temp = []
    let answers = []

    if (data[num]) {
      var multiple = data[num]?.multiple==="1" //convertStringToBoolean(data[step]?.multiple)
      const q = data[num]?.pregunta
      const regex = /^respuesta\d/g

      Object.entries(data[num]).forEach(entry => {
        const [key, value] = entry
        //console.log(value)
        if (key.match(regex) && value) {
          answers.push(value)
        }
      })

      temp.push({
        'question': q,
        'answers': answers
      })
      /*console.log("DATA:", data[step]) 
      console.log("TEMP:", temp)
      console.log("NUM:", num)
      console.log("STEP:", step)      
      console.log("multiple",multiple)
      console.log("DATA[NUM]", data[num])*/
      setIsMultiple(multiple)//multiple)
      setDataFilteredByStep(temp)
    }
  }

  useEffect(() => {    
    props.setPage('team');
    PreguntasEquipos.get().then( 
      data => {        
        setData(data);
        setNumSteps(data.length);
        setLoading(false);
        //TODO: set step to the last preunt without respuesta if all have one go to choose!!
        var index = 0;
        data.forEach( item =>{
          if (item.respuesta !== null){
            index++;
          }
        })
        setStep(index);
        if (index >= data.length){
          //navigate("/team/choose");
          navigate("/team/check");
        }
      }
    )
  }, [])

  useEffect(() => {
    if (data) {
      buildDataFromApi(step)
    }
  }, [data])

  const onOptionChange = e => {
    setIsChecked(e.target.value)
    setAnswer(e.target.value)
  }

  const onMultipleOptionChange = e => {
    setIsChecked(e.target.value)
    setAnswer(answer+"|"+e.target.value)
  }

  // Next step
  const goToNextStep = () => {
    setAnswer('')
    setIsChecked('')
    let numStep = step+1
    setStep(numStep)
    buildDataFromApi(numStep)
  }

  const submitTest = e => {
    e.preventDefault()

    // Send step and answer to API
    //const result = `${step+1} | ${answer}`
    //console.log(result)
    RespuestasEquipos.post(step+1, answer).then(data => {
      if (data !== null) {
        //console.log("enviado!!", step+1)
        if (step+1 === numSteps){
          //navigate("/team/choose");    
          navigate("/team/check");
        }
      }
    })
    if (step <= numSteps) {
      goToNextStep()
    } 
  }

	return <main className="test">
    { (loading === true )?<div className="spinner-wrapper">< div className="spinner"/></div>:
    <div className="container">
      <div className="content">       

        <div className="test_steps">
          <form className="form" onSubmit={submitTest}>
            { 
              !isMultiple?
              <Step 
                step={step} 
                dataFilteredByStep={dataFilteredByStep} 
                isChecked={isChecked}
                onOptionChange={onOptionChange} 
              />
              :
              <StepMultiple
                step={step} 
                dataFilteredByStep={dataFilteredByStep} 
                onOptionChange={onMultipleOptionChange} 
              />
            }

            <footer className="test_footer">
              <button className="btn btnPrimary" disabled={!answer}>
              <FormattedMessage
                id = "app.test.next"
                defaultMessage="Next"
              />
              </button>

              <div className="test_progress">
                <ul>
                  { Array.from(Array(numSteps), (e, idx) => {
                    let num = idx+1
                    return <li key={`progress-${num}`} className={step+1 === num ? 'active' : ''}>{num}</li>
                  })}
                </ul>
              </div>
            </footer>
          </form>
        </div>
      </div>
    </div>}
	</main>
}

export default Test