import React, { useEffect, useContext } from 'react'
import { Context } from "../lang/Wrapper"
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { setUser } from '../../actions'
import axios from 'axios'
import Sustainability1 from '../../assets/images/sustainability1.jpg'
import Learn from '../../assets/images/learn.png'

const Sustainability = props => {
  const intl = useIntl()  
  const context = useContext(Context); 
  var instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 16000,
    headers: {'Authorization': 'Bearer ' + localStorage.getItem('JWT') }
  });
  

  useEffect(() => {
    window.scrollTo(0, 0);
    props.setPage('sustainability');   
    if (localStorage.getItem("JWT") !== null){
      instance.get('/api/user')
      .then( (response) =>{
          window.dataLayer.push({
              'userId' : response.data.user.id
          }); 
          props.setUser({"id": response.data.user});
          context.selectLanguage(response.data.user.idioma);   
      })
      .catch( () =>{   
      })        
    }     
  }, []); 

  return <main className="landing awards learn">    
    <div className="wrapper-awards">
      <div className="info">
        <h2>{intl.formatMessage({ id: 'app.sustainability.title' })}</h2>
        <p>{intl.formatMessage({ id: 'app.sustainability.committed' })}</p>
      </div>
    </div>

    <div className="wrapper">
      <div className="container">
        <div className="mod-item">
          <div className="rwd-media">
            <img src={Sustainability1} alt="" />
          </div>

          <div className="rwd-info">
            <h2> {intl.formatMessage({ id: 'app.sustainability.wheel' })}</h2>
            <p>{intl.formatMessage({ id: 'app.sustainability.regulation' })}</p>
          </div>
        </div>

        <div className="mod-item reverse">
          <div className="rwd-media">
            <img src={Learn} alt="" />
          </div>

          <div className="rwd-info">
            <h2> {intl.formatMessage({ id: 'app.sustainability.how' })}</h2>
            <p>{intl.formatMessage({ id: 'app.sustainability.adopt' })}</p>     
          </div>
        </div>
      </div>
    </div>    
  </main>
}

const mapStateToprops = (state)=>{        
  return {...state};  
}
export default connect(mapStateToprops,{setUser:setUser})(Sustainability)