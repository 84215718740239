import { useState, useEffect } from 'react'

const Step = ({ step, dataFilteredByStep, isChecked, onOptionChange }) => {
  const [dataStep, setDataStep] = useState([])

  useEffect(() => {
    if (dataFilteredByStep.length > 0) {
      setDataStep(dataFilteredByStep[0].answers)
    }
  }, [dataFilteredByStep])

  return <>
  <h2>{dataFilteredByStep[0]?.question}</h2>
  <div className="test_step1">
    {/*<div className="test_question">
      <p>{dataFilteredByStep[0]?.question}</p>
    </div>*/}

    <div className="test_answer">
      <ul>
        {
          dataStep?.map((item, idx) => {

            return <li key={`answer-${step}-${idx}`}>
              <input
                type="radio"
                id={`rbTest-${step}-${idx}`}
                name="rbsTest"
                value={item}
                checked={isChecked === item}
                onChange={onOptionChange}
              />
              <label htmlFor={`rbTest-${step}-${idx}`}><span>{item}</span></label>
            </li>
          })
        }
      </ul>
    </div>
  </div>
  </>
}

export default Step