import {React/*, useContext*/} from 'react'
import { useIntl } from 'react-intl';
//import {Context} from "../lang/Wrapper"


const Item = props => {  
    //const context = useContext(Context);
    const intl = useIntl();    

    const getPosition = (number) =>{
        /*var locale =  context.locale.toLowerCase();        
        
        if (locale === "en"){*/
            if (number%10===1)return <sup>{ intl.formatMessage({ id:"app.awards.ord.1"})}</sup>;
            if (number%10===2)return <sup>{ intl.formatMessage({ id:"app.awards.ord.2"})}</sup>;
            if (number%10===3)return <sup>{ intl.formatMessage({ id:"app.awards.ord.3"})}</sup>;
            return <sup>{ intl.formatMessage({ id:"app.awards.ord.4"})}</sup>;
        /*}
        if (locale ==="es"){
            return isDate?"":intl.formatMessage({ id:"app.awards.ord.1"});
        }
        if (locale === "pl"){            
            return intl.formatMessage({ id:"app.awards.ord.1"});//number;
        }
        return "";    */
    }


  return <div className={ props.empty?"carousel-item empty":"carousel-item"}>
      <div className="item-wrapper">
        <div className="top">
            <img src={props.image}/>
        </div>
        <div className="bottom">
            {props.number>0?<div className="flex">

            {
                    /*document.documentElement.lang !== 'pl' ?*/
                    <> 
                        <h3>{props.number}{getPosition(props.number)} { intl.formatMessage({ id:"app.dashboard.position"})}</h3>
                    </>
                    /*: null*/
                }

            {/*
                    document.documentElement.lang === 'pl' ?
                    <> 
                        <h3>{ intl.formatMessage({ id:"app.dashboard.position"})} {props.number}{getPosition(props.number).slice(0, -1)} </h3>
                    </>
                    : null
            */}
                
                <div className="laurel"><span>{props.number}</span></div>
            </div>:<div className="flex">
                <h3>{props.title}</h3></div>}
            {props.subtitle!==""?<p className="subtitle">{props.subtitle}</p>:null}
            <p>{props.text}</p>
        </div>
      </div>
  </div>
}

export default Item