import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'


const Legal = props => {

  useEffect(() => {
    window.scrollTo(0, 0);
    props.setPage('legal-advice');
  }, []); 

  return <main className="cookies">
          <div className="wrapper-cookies">
            <h2><FormattedMessage id = "app.legaladvice.title"/></h2>
          </div>
          <div className="info">  
            <p><FormattedMessage id = "app.legaladvice.1"/></p>
            <p><FormattedMessage id = "app.legaladvice.2"/></p>
            <p><FormattedMessage id = "app.legaladvice.3"/></p>
            <p><strong><FormattedMessage id = "app.legaladvice.4"/></strong></p>
            <p><strong><FormattedMessage id = "app.legaladvice.5"/></strong></p>
            <p><FormattedMessage id = "app.legaladvice.6"/></p>
            <p><FormattedMessage id = "app.legaladvice.7"/></p>
            <p><FormattedMessage id = "app.legaladvice.8"/></p>
            <p><FormattedMessage id = "app.legaladvice.9"/></p>
            <p><FormattedMessage id = "app.legaladvice.10"/></p>
            <p><strong><FormattedMessage id = "app.legaladvice.11"/></strong></p>
            <p><FormattedMessage id = "app.legaladvice.12"/></p>
            <p><FormattedMessage id = "app.legaladvice.13"/></p>
            <p><FormattedMessage id = "app.legaladvice.14"/></p>
            <p><strong><FormattedMessage id = "app.legaladvice.15"/></strong></p>
            <p><FormattedMessage id = "app.legaladvice.16"/></p>
            <p><FormattedMessage id = "app.legaladvice.17"/></p>
            <p><FormattedMessage id = "app.legaladvice.18"/></p>
            <p><strong><FormattedMessage id = "app.legaladvice.19"/></strong></p>
            <p><FormattedMessage id = "app.legaladvice.20"/></p>
          </div>
        </main>
}
const mapStateToprops = (state)=>{        
  return {...state};  
}
export default connect(mapStateToprops)(Legal)