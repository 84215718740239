import React, { useEffect } from 'react'
import { connect } from 'react-redux'
//import flag  from '../../assets/images/flag.svg'
import {  useIntl } from 'react-intl'
import arrow  from '../../assets/images/select-arrow.svg'
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const IntroContent = props => {
    const intl = useIntl();
    const colorBase = "#444444";
	const colorAccent = "#FFFFFF";
	//console.log(props.time)

	const onFinish = () => {
		props.next();
        //console.log("finish!!");
	}

  useEffect(() => {
   
  }, []); 
  


  return <div className="wrapper-intro">
    <div className="flex">
        <div className="colLeftIntro">
            <img src={props.image} alt="" />
            <div className="dots">
                <ul>
                    <li className={props.active===1?"active":""}></li>
                    <li className={props.active===2?"active":""}></li>
                    <li className={props.active===3?"active":""}></li>
                    <li className={props.active===4?"active":""}></li>
                    <li className={props.active===5?"active":""}></li>
                    <li className={props.active===6?"active":""}></li>
                </ul>
            </div>
        </div>

        <div className="colRightIntro">
            <div className="info">
                <h2>
                {props.title}
                </h2>
                <p>{props.text}</p>
            </div>
        </div>
        </div>
        <div className="buttons">
            <button onClick={ ()=>{props.skip()}} className="skip">{intl.formatMessage({ id:"app.intro.skip"})}</button>
            <div className="timer">
                <CountdownCircleTimer
                        key={props.key}
                        isPlaying
                        initialRemainingTime={30}
                        duration={30}
                        size="58"
                        strokeWidth={5}
                        trailStrokeWidth={4}
                        strokeLinecap="square"
                        colors={[colorBase]}
                        trailColor={[colorAccent]}
                        onComplete={onFinish}>            
                </CountdownCircleTimer>
                <button onClick={()=>{props.next()}} className="next"><img src={arrow} /></button>
            </div>
        </div>
    </div>  
}
const mapStateToprops = (state)=>{        
  return {...state};  
}
//export default Login
export default connect(mapStateToprops)(IntroContent)